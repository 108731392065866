import 'angular-route';
import 'Injector/angularModule';
import 'Iguana';
import 'FrontRoyalConfig/angularModule';
import 'PiggybackOnNg/angularModule';
import 'ErrorLogging/angularModule';
import 'AngularHttpQueueAndRetry/angularModule';
import 'SessionTracker/angularModule';

export default angular.module('EventLogger', [
    'AClassAbove',
    'FrontRoyal.Config',
    'SuperModel',
    'ngRoute',
    'PiggybackOnNg',
    'Iguana',
    'FrontRoyal.ErrorLogService',
    'HttpQueueAndRetry',
    'sessionTracker',
    'Injector',
]);
