import { type EventLogger } from './EventLogger.types';

export default {
    logger: null as EventLogger | null,
    get() {
        return this.logger;
    },

    set(val: EventLogger | null) {
        this.logger = val;
    },
};
