
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.email_input = window.Smartly.locales.modules.en.back_royal.email_input || {};
window.Smartly.locales.modules.en.back_royal.email_input.email_validation = window.Smartly.locales.modules.en.back_royal.email_input.email_validation || {};
window.Smartly.locales.modules.en.back_royal.email_input.email_validation = {...window.Smartly.locales.modules.en.back_royal.email_input.email_validation, ...{
    "please_use_company_email": "Please use your company email address",
    "please_enter_valid_email": "Please enter a valid email address",
    "please_enter_matching_domain": "Please enter an email address with the domain @{{requireDomain}}",
    "please_use_non_relay_email": "Please use a non-relayed email address"
}
}
    