import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import parseHtml from 'html-react-parser';
import { ExecEdCertCategoryConfigs } from '../helpers/ExecEdCategories';
import { type ExecEdEligibilityBundle, type ExecEdCertCategory } from '../ExecEdEligibility.types';

type Props = {
    filteredEligibilityBundle: ExecEdEligibilityBundle;
    showCertCongratsModal: () => void;
};

export function ExecEdEligibilityBundleBox({ filteredEligibilityBundle, showCertCongratsModal }: Props) {
    const { t } = useTranslation('back_royal');
    const categoriesEligibleFor = useMemo(() => {
        const categories: ExecEdCertCategory[] = [];
        const eligibleProgramTypes = filteredEligibilityBundle?.eligiblePrograms.map(p => p.programType);

        Object.entries(ExecEdCertCategoryConfigs).forEach(([category, config]) => {
            if (config.programTypes.some(p => eligibleProgramTypes?.includes(p))) {
                categories.push(category as ExecEdCertCategory);
            }
        });

        return categories;
    }, [filteredEligibilityBundle]);

    const openCongratsModal = useCallback(() => {
        showCertCongratsModal();
    }, [showCertCongratsModal]);

    return (
        <div className="tw-flex tw-flex-col tw-rounded tw-bg-gradient-to-br tw-from-[#2266D0] tw-to-[#0C2C63] tw-p-[15px] tw-text-white">
            <div className="tw-flex tw-justify-between">
                <h2 className="tw-m-0 tw-text-lg tw-font-semibold tw-text-white">
                    {parseHtml(t('exec_ed_eligibility.bundle_box.exec_ed_programs'))}
                </h2>
                <div className="tw-relative -tw-rotate-[15deg] tw-text-yellow">
                    <i className="fa fa-sharp fa-seal tw-rotate-[23deg] tw-text-[40px]" />
                    <i className="fa fa-sharp fa-seal tw-absolute tw-right-0 tw-text-[40px]" />
                    <p className="tw-align-center tw-absolute tw-top-0 tw-h-full tw-w-full tw-text-center tw-text-[11px] tw-font-semibold tw-leading-[40px] tw-text-white tw-text-shadow">
                        {t('exec_ed_eligibility.bundle_box.new')}
                    </p>
                </div>
            </div>
            <p className="tw-mb-0 tw-pb-[15px] tw-pt-[15px] tw-text-[15px] tw-leading-[21px]">
                {t('exec_ed_eligibility.bundle_box.eligible_to_enroll')}
            </p>
            <ul className="tw-mb-[20px] tw-list-none tw-p-0">
                {categoriesEligibleFor.map(category => (
                    <li key={category} className="tw-mb-[2px] tw-flex tw-items-center tw-font-semibold tw-text-white">
                        <i className="fa-light fa-hexagon-check -tw-mt-[1px] tw-me-[7.5px] tw-text-[13px]" />
                        {t(`exec_ed_eligibility.bundle_box.${category}`)}
                    </li>
                ))}
            </ul>
            <button
                type="button"
                className="tw-mb-[5px] tw-mt-0 tw-h-auto tw-self-center tw-rounded-[50px] tw-border-[1px] tw-bg-transparent tw-px-[22px] tw-py-[10px] tw-text-[15px] tw-font-semibold tw-normal-case tw-leading-[15px] tw-text-white tw-transition-colors hover:tw-bg-white hover:tw-text-[#194695]"
                onClick={() => openCongratsModal()}
            >
                {t('exec_ed_eligibility.bundle_box.review_and_register')}
            </button>
        </div>
    );
}
