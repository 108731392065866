// see http://stackoverflow.com/questions/18604374/angularjs-orderby-with-empty-field
export default angular.module('emptyToEndFilter', []).filter('emptyToEnd', () => (array, keyOrFunction) => {
    if (!angular.isArray(array)) {
        return;
    }

    function itemHasValue(item) {
        const blankValues = {
            null: true,
            undefined: true,
            '': true,
        };
        return typeof keyOrFunction === 'function' ? keyOrFunction(item) : !blankValues[item[keyOrFunction]];
    }

    const present = array.filter(item => itemHasValue(item));
    const empty = array.filter(item => !itemHasValue(item));
    return present.concat(empty);
});
