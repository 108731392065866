
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility = window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility || {};
window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility.bundle_box = window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility.bundle_box || {};
window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility.bundle_box = {...window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility.bundle_box, ...{
    "new": "NEW",
    "exec_ed_programs": "Executive Education <br/> Programs",
    "eligible_to_enroll": "Based on your interests and experience, you are eligible to enroll in new Quantic certificates.",
    "ai_and_future_of_business": "AI & Future of Business Programs",
    "data_and_finance": "Data & Finance Programs",
    "rise_to_c_suite": "Rise to the C-Suite Programs",
    "review_and_register": "Review All and Register"
}
}
    