import angularModule from 'EventLogger/angularModule/scripts/event_logger_module';
import { generateGuid } from 'guid';
import { frontRoyalHref } from 'FrontRoyalHref';
import { getDirectiveForEvents } from 'Navigation';

/*

    A class that represents a single event that happens at a particular time.

    This should really be an Iguana class, but since we want to do batch saving
    and Iguana doesn't support that, we have an EventBundle Iguana class instead.
    If Iguana supports batch saves in the future, we would probably get rid of EventBundle
    and then convert this to an Iguana class.

*/
angularModule.factory('EventLogger.Event', [
    '$injector',
    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const $route = $injector.get('$route');
        const $location = $injector.get('$location');
        const $window = $injector.get('$window');
        const ConfigFactory = $injector.get('ConfigFactory');
        const SessionTracker = $injector.get('SessionTracker');
        const injector = $injector.get('injector');
        const offlineModeManager = injector.get('offlineModeManager', { optional: true });

        const Event = SuperModel.subclass(() => ({
            initialize(event_type, obj, date) {
                if (!event_type) {
                    throw new Error('no event_type provided');
                }

                if (date) {
                    // we switched to using Date.now
                    throw new Error('No longer supported');
                }

                this.clientTimestamp = Date.now();
                this.event_type = event_type;

                if (obj.id) {
                    throw new Error(
                        'Do not pass an id into the payload of an event.  One will be generated automatically',
                    );
                }

                this.properties = angular.extend(
                    {
                        event_type,
                        id: generateGuid(),
                        cordova: !!$window.CORDOVA,
                        in_offline_mode: offlineModeManager?.inOfflineMode || false,
                    },
                    obj,
                );

                this._addRouteInfo();
            },

            asJson() {
                this._addTimes();

                // This relies on server timestamp, so we wait and do it when we do _addTimes
                this.properties.client_session_id = SessionTracker.pingCurrentSession('client', 20 * 60 * 1000).id;

                return angular.extend({}, this.properties, {
                    buffered_time: (Date.now() - this.clientTimestamp) / 1000,
                });
            },

            // add some properties indicating the amount of time
            // between some start event and this event.
            addDurationInfo(startEvent, namespace) {
                const obj = {
                    duration_total: (this.clientTimestamp - startEvent.clientTimestamp) / 1000,
                };

                if (namespace) {
                    this.properties[namespace] = obj;
                } else {
                    angular.extend(this.properties, obj);
                }

                return this;
            },

            // separate method can be mocked in tests
            _addTimes() {
                const date = new Date(this.clientTimestamp);
                this.serverTimestamp = ConfigFactory.getServerTimestamp(this.clientTimestamp);
                angular.extend(this.properties, {
                    server_timestamp: this.serverTimestamp / 1000,
                    client_utc_timestamp: this.clientTimestamp / 1000,
                    client_local_time: [
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate(),
                        date.getHours(),
                        date.getMinutes(),
                        date.getSeconds(),
                        date.getMilliseconds(),
                    ],
                    client_utc_time: [
                        date.getUTCFullYear(),
                        date.getUTCMonth(),
                        date.getUTCDate(),
                        date.getUTCHours(),
                        date.getUTCMinutes(),
                        date.getUTCSeconds(),
                        date.getUTCMilliseconds(),
                    ],
                    client_offset_from_utc: date.getTimezoneOffset() / 60,
                });
            },

            _addRouteInfo() {
                this.properties.url ||= frontRoyalHref();

                // With shift to react, "directive" is an odd name for this property, but it's
                // hard to change the column name in the database, so we're sticking with it
                this.properties.directive ||= getDirectiveForEvents($route, $location);
            },
        }));

        return Event;
    },
]);
