
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.dialog_modals = window.Smartly.locales.modules.en.back_royal.dialog_modals || {};
window.Smartly.locales.modules.en.back_royal.dialog_modals.dialog_modal_alert = window.Smartly.locales.modules.en.back_royal.dialog_modals.dialog_modal_alert || {};
window.Smartly.locales.modules.en.back_royal.dialog_modals.dialog_modal_alert = {...window.Smartly.locales.modules.en.back_royal.dialog_modals.dialog_modal_alert, ...{
    "confirm_action_ok": "OK",
    "confirm_action_cancel": "Cancel",
    "confirm_thanks": "Thanks for letting us know.",
    "confirm_delete_with_typing_body": "Are you sure you wish to delete <span class=\"thing\">{{thing}}</span>? <br><br>Type <strong>{{confirmationText}}</strong> below to confirm.",
    "confirm_action_delete": "Delete",
    "confirm_by_typing_title": "Confirm Deletion Request"
}
}
    