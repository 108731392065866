import { type ErrorLogService } from './ErrorLogService';

export default {
    ErrorLogService: null as typeof ErrorLogService | null,
    get() {
        return this.ErrorLogService;
    },

    set(val: typeof ErrorLogService) {
        this.ErrorLogService = val;
    },
};
