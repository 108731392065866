import { type StreamIguanaObject } from 'Lessons';
import { type CurrentUserIguanaObject } from 'Users';
import {
    examVerificationRecordForExamVerificationSession,
    unclaimedAdminExamVerificationRecordForStream,
} from './examVerificationRecordForStream';

// If the user has an unclaimed ExamVerification for this Stream and doesn't also have an
// ExamVerification using the current ExamVerificationSessionId, they need to claim the
// unclaimed ExamVerification before launching the stream.
export function mustClaimExamVerificationToLaunchStream(user?: CurrentUserIguanaObject, stream?: StreamIguanaObject) {
    // Some Streams are made available to the public and don't require an authenticated user
    // in order to launch them.
    // Additionally, not all Lessons have a Stream associated with them.
    if (!user || !stream) {
        return false;
    }

    return !!(
        unclaimedAdminExamVerificationRecordForStream(user, stream) &&
        !examVerificationRecordForExamVerificationSession(user, stream)
    );
}
