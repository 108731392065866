import angularModule from 'DragAndDrop/angularModule/scripts/lib/drag_and_drop';

angularModule.directive('draggable', [
    'DraggableDirHelper',
    DraggableDirHelper => ({
        link(scope, elem, attrs) {
            new DraggableDirHelper(elem, scope, attrs);
        },
    }),
]);
