import { setAuthHeaders } from 'ReduxHelpers';
import { type StreamIguanaObject } from 'Lessons';
import { type CurrentUserIguanaObject } from 'Users';
import { unclaimedAdminExamVerificationRecordForStream } from './examVerificationRecordForStream';
import { getOrGenerateExamVerificationSessionId } from './getOrGenerateExamVerificationSessionId';

const baseApiUrl = `${window.ENDPOINT_ROOT}/api/exam_verifications/claim/`;

export async function claimExamVerification(user: CurrentUserIguanaObject, stream: StreamIguanaObject) {
    const requestInit = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            ...setAuthHeaders(new Headers()),
        },
        body: JSON.stringify({
            exam_verification_id: unclaimedAdminExamVerificationRecordForStream(user, stream)?.id,
            exam_verification_session_id: getOrGenerateExamVerificationSessionId(stream),
        }),
    };
    return fetch(`${baseApiUrl}`, requestInit);
}
