import angularModule from 'EventLogger/angularModule/scripts/event_logger_module';
/*

	An iguana class that represents a bunch of events who should
	all be saved in one api call.

	We only need this because Iguana has no support for batch saves
	right now.  Once there is batch save support in Iguana, we should
	use an Event model instead.

*/
angularModule.factory('EventLogger.EventBundle', [
    '$injector',
    $injector => {
        const Iguana = $injector.get('Iguana');

        return Iguana.subclass(function () {
            this.setCollection('event_bundles');
        });
    },
]);
