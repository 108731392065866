import { linearBlueGradientStyle, linearMaskImageGradient } from 'ExecEdEligibility/helpers/gradients';
import { type CurrentUserIguanaObject } from 'Users';
import { type navigationHelper } from 'navigationHelper';
import { type ProgramTypeConfig } from 'Program';
import { type ExecEdEligibilityBundle } from 'ExecEdEligibility/ExecEdEligibility.types';
import { CertModalContent } from './CertModalContent';
import { CertModalHeader } from './CertModalHeader';

type Props = {
    currentUser: CurrentUserIguanaObject;
    filteredEligibilityBundle: ExecEdEligibilityBundle;
    programTypeConfigs: { [programTypeKey: string]: ProgramTypeConfig };
    loadUrl: ReturnType<typeof navigationHelper>['loadUrl'];
};

export function CertCongratsModal({ currentUser, filteredEligibilityBundle, programTypeConfigs, loadUrl }: Props) {
    return (
        <div
            className="cert-accepted-modal tw-flex tw-h-screen tw-max-h-screen tw-w-full tw-flex-col tw-overflow-y-auto tw-font-apercu tw-text-white"
            style={{ ...linearBlueGradientStyle('150deg') }}
            data-testid="cert-accepted-modal"
        >
            {/* Modal Header */}
            <div
                // This and some other divs are sticky at md+ widths so that the whole modal container may scroll but only the 'Additional Programs' actually moves
                className="tw-mb-[30px] tw-flex tw-flex-col tw-items-center tw-justify-start tw-p-5 md:tw-sticky md:tw-top-0 md:tw-z-10 md:tw-mb-0 md:tw-pb-[80px]"
                style={{
                    // Masks the header so that the 'Additional Programs' fade out at edge when scrolled
                    ...linearMaskImageGradient('to top'),
                    // Adds the same bg as the general modal bg (and at same size),
                    // so that at desktop width we can scroll the content behind it without it showing through.
                    ...linearBlueGradientStyle('150deg'),
                    backgroundSize: '100vw 100vh',
                }}
            >
                <CertModalHeader name={currentUser.name} />
            </div>

            {/* Modal Content */}
            <div className="tw-relative tw-mx-auto tw-w-full md:-tw-top-[25px] md:tw-flex md:tw-max-w-[85%] md:tw-flex-1 md:tw-flex-col md:tw-pt-[40px]">
                <CertModalContent
                    filteredEligibilityBundle={filteredEligibilityBundle}
                    programTypeConfigs={programTypeConfigs}
                    loadUrl={loadUrl}
                    currentUser={currentUser}
                />
            </div>
        </div>
    );
}
