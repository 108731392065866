/* eslint-disable func-names */

import angularModule from 'ContentItem/angularModule/scripts/content_item_module';

angularModule.factory('IsContentItem', [
    '$injector',

    $injector => {
        const AModuleAbove = $injector.get('AModuleAbove');
        const $filter = $injector.get('$filter');
        const TranslationHelper = $injector.get('TranslationHelper');
        const guid = $injector.get('guid');
        const translationHelper = new TranslationHelper('content_item.is_content_item_mixin');
        const LocalePack = $injector.get('LocalePack');
        const ErrorLogService = $injector.get('ErrorLogService');
        const rootScope = $injector.get('$rootScope');

        return new AModuleAbove({
            included(target) {
                target.embedsOne('locale_pack', 'LocalePack');
                target.supportsContentFamilyIdentifier = false; // Classes that include this mixin can override this as needed.

                Object.defineProperty(target.prototype, 'factoryName', {
                    get() {
                        throw new Error('Content Items should defined factoryName');
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'publishedAt', {
                    get() {
                        return (this.published_at && new Date(1000 * this.published_at)) || undefined;
                    },
                });

                Object.defineProperty(target.prototype, 'sortablePublishedAt', {
                    get() {
                        return this.published_at || '';
                    },
                });

                Object.defineProperty(target.prototype, 'modifiedAt', {
                    get() {
                        return (this.modified_at && new Date(1000 * this.modified_at)) || undefined;
                    },
                });

                Object.defineProperty(target.prototype, 'sortableModifiedAt', {
                    get() {
                        return this.modified_at || '';
                    },
                });

                Object.defineProperty(target.prototype, 'modified_at_date', {
                    get() {
                        return this.formatDate(this.modified_at) || translationHelper.get('unknown');
                    },
                });

                Object.defineProperty(target.prototype, 'modifiedAfterPublished', {
                    get() {
                        return this.modifiedAt && this.publishedAt && this.modifiedAt > this.publishedAt;
                    },
                });

                Object.defineProperty(target.prototype, 'last_published_date', {
                    get() {
                        return this.formatDate(this.published_at) || translationHelper.get('unknown');
                    },
                });

                Object.defineProperty(target.prototype, 'hasPublishedVersion', {
                    get() {
                        return !!this.published_at;
                    },
                });

                Object.defineProperty(target.prototype, 'englishTranslation', {
                    get() {
                        return this.locale !== 'en' && this.locale_pack && this.locale_pack.contentItemForLocale('en');
                    },
                });

                Object.defineProperty(target.prototype, 'englishTitle', {
                    get() {
                        return this.englishTranslation ? this.englishTranslation.title : '';
                    },
                });

                Object.defineProperty(target.prototype, 'englishTitleWithBrackets', {
                    get() {
                        if (!this.englishTitle) {
                            return '';
                        }
                        return this.title.search(/^(Duplicated from)/) >= 0 ? '' : ` [${this.englishTitle}]`;
                    },
                });

                Object.defineProperty(target.prototype, 'titleWithTag', {
                    get() {
                        return this.tag ? `[${this.tag}] ${this.title}` : this.title;
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'titleWithTagAndLocales', {
                    get() {
                        return (
                            this.titleWithTag +
                            (this.locale_pack ? this.locale_pack.availableNonEnglishLocaleString : '')
                        );
                    },
                });

                Object.defineProperty(target.prototype, 'pinnedVersions', {
                    get() {
                        if (!this.version_history) {
                            return [];
                        }
                        const pinnedVersions = [];
                        this.version_history.forEach(item => {
                            if (item.pinned) {
                                pinnedVersions.push(item);
                            }
                        });
                        return pinnedVersions;
                    },
                });

                Object.defineProperty(target.prototype, 'canChangeLocale', {
                    get() {
                        // can only change locale if there is no locale pack or if this
                        // item is the only one in the locale pack
                        return !this.locale_pack || this.locale_pack.content_items.length === 1;
                    },
                });

                Object.defineProperty(target.prototype, 'launchText', {
                    get() {
                        if (this.complete) {
                            return rootScope.currentUser?.preferStrictSmartCaseScore
                                ? translationHelper.get('review')
                                : translationHelper.get('retake');
                        }
                        if (this.started) {
                            return translationHelper.get('resume');
                        }
                        return translationHelper.get('start');
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'complete', {
                    get() {
                        throw new Error('Classes that include IsContentItem should defined "complete"');
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'started', {
                    get() {
                        throw new Error('Classes that include IsContentItem should defined "started"');
                    },
                    configurable: true,
                });

                Object.defineProperty(target.prototype, 'editorUrl', {
                    get() {
                        return this.constructor.editorUrl(this.id);
                    },
                });

                Object.defineProperty(target.prototype, 'localePackId', {
                    get() {
                        return this.locale_pack ? this.locale_pack.id : null;
                    },
                });

                // I came across a couple subtle bugs where we were referencing
                // locale_pack_id, which we don't send back from the server.
                // Instead, on the server we send back a locale_pack and
                // in the client we have defined a localePackId property. So,
                // we should either send back the raw locale_pack_id or alert
                // if we accidentally use it. I'm choosing to do the latter for now
                // since it is easier.
                Object.defineProperty(target.prototype, 'locale_pack_id', {
                    get() {
                        ErrorLogService.notify(new Error('Trying to use locale_pack_id'));
                        return this.localePackId;
                    },
                });

                Object.defineProperty(target.prototype, 'groupNames', {
                    get() {
                        if (!this.constructor.groupable) {
                            throw new Error('Cannot call groupNames on non-groupable contentItem');
                        }
                        return this.locale_pack ? this.locale_pack.groupNames : [];
                    },
                });

                Object.defineProperty(target.prototype, 'groupNamesMinusSuperviewer', {
                    get() {
                        return _.without(this.groupNames, 'SUPERVIEWER');
                    },
                });

                Object.defineProperty(target.prototype, 'authorName', {
                    get() {
                        return this.author ? this.author.name : '';
                    },
                });

                Object.defineProperty(target.prototype, 'assessmentEditingLocked', {
                    get() {
                        return this.locale_pack ? this.locale_pack.assessment_editing_locked : false;
                    },
                    configurable: true,
                });

                target.defineSetter('locale_pack', function (newLocalePack) {
                    const self = this;
                    if (!self.locale && newLocalePack) {
                        throw new Error('Cannot assign localePack if there is no locale');
                    }

                    if (self.locale_pack && !newLocalePack) {
                        // if removing the locale pack from this content item,
                        // also remove this content item from the locale pack
                        self.locale_pack.content_items = _.reject(
                            self.locale_pack.content_items,
                            contentItem => contentItem.id === self.id,
                        );
                        return this.writeKey('locale_pack', null);
                    }
                    if (!newLocalePack) {
                        return this.writeKey('locale_pack', null);
                    }

                    // if there is another content item already in the
                    // pack with the same locale as this one, remove it, since
                    // we cannot have to content items with the same locale in
                    // the same pack
                    newLocalePack.content_items = _.reject(
                        newLocalePack.content_items,
                        contentItem => contentItem.locale === self.locale,
                    );

                    // push this item onto the locale pack
                    newLocalePack.content_items.push({
                        id: self.id,
                        title: self.title,
                        locale: self.locale,
                    });

                    this.writeKey('locale_pack', newLocalePack);

                    return newLocalePack;
                });
            },

            formatDate(timestamp) {
                if (!timestamp) {
                    return undefined;
                }
                const d = new Date(timestamp * 1000);
                return $filter('amDateFormat')(d, 'l h:mm:ss a');
            },

            imageUploadUrl() {
                // FIXME: https://trello.com/c/vQM1wl9b/906-chore-incorporate-iguana-api-into-content-item-mixin-imageuploadurl
                const id = this[this.constructor.idProperty];
                const collection = this.constructor.collection;
                return `${window.ENDPOINT_ROOT}/api/${collection}/${id}/images.json`;
            },

            ensureLocalePack() {
                if (!this.locale_pack) {
                    // the locale_pack setter above
                    // will add this content item to the new locale pack
                    this.locale_pack = LocalePack.new({
                        id: guid.generate(),
                        content_items: [],
                    });

                    if (this.constructor.supportsContentFamilyIdentifier) {
                        this.locale_pack.content_family_identifier = this.id;
                    }
                }
                return this.locale_pack;
            },

            duplicate(params, meta) {
                meta = angular.extend({}, meta, {
                    duplicate_from_id: this.id,
                    duplicate_from_updated_at: this.updated_at,
                });
                return this.constructor.create(params, meta);
            },

            inGroup(groupName) {
                if (!this.constructor.groupable) {
                    throw new Error('Cannot call inGroup on non-groupable contentItem');
                }
                return this.locale_pack && this.locale_pack.inGroup(groupName);
            },

            titleForUser(user) {
                const contentItem =
                    this.locale_pack && this.locale_pack.getContentItem([user ? user.pref_locale : 'en', 'en']);
                return contentItem ? contentItem.title : this.title;
            },
        });
    },
]);
