import angularModule from 'EventLogger/angularModule/scripts/event_logger_module';

angularModule.factory('EventLoggerInterceptor', [
    '$injector',

    $injector => ({
        response(response) {
            const EventLogger = $injector.get('EventLogger');

            let messages;

            // this try catch just catchess null refs
            try {
                messages = response.data.meta.push_messages.event_logger;
            } catch (e) {
                return response;
            }

            // this try/catch is us being
            // super careful that this code never breaks everything due to
            // some unexpected error
            if (messages) {
                try {
                    _.forEach(messages.disable_auto_events_for, directive => {
                        EventLogger.trackAllNgEvents(directive, false);
                    });
                } catch (e) {
                    $injector.get('ErrorLogService').notifyInProd(e);
                }
            }

            return response;
        },
    }),
]);

angularModule.config([
    '$httpProvider',
    $httpProvider => {
        $httpProvider.interceptors.push('EventLoggerInterceptor');
    },
]);
