import angularModule from 'DialogModal/angularModule/scripts/dialog_modal';
import template from 'DialogModal/angularModule/views/partial_screen_dialog_modal.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('partialScreenDialogModal', [
    '$injector',
    function factory($injector) {
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {
                header: '<',
                body: '<?',
                bodyLocale: '<?',
                buttonText: '<',
                action: '&',
                blurTargetSelector: '<',
            },

            link(scope) {
                NavigationHelperMixin.onLink(scope);

                let blurTarget = scope.blurTargetSelector ? $(scope.blurTargetSelector) : null;
                let initialWekbitFilter;
                let initalFilter;
                if (blurTarget) {
                    initialWekbitFilter = blurTarget.css('-webkit-filter');
                    initalFilter = blurTarget.css('filter');
                }

                // Selectively apply filter changes specific DOM elements
                scope.$watch('blurTargetSelector', val => {
                    blurTarget = val ? $(val) : null;

                    if (blurTarget) {
                        blurTarget.css('-webkit-filter', 'blur(3px)');
                        blurTarget.css('filter', 'blur(3px)');
                    }
                });

                scope.$on('$destroy', () => {
                    $('partial-screen-dialog-modal').remove();

                    // remove/restore filter changes if possible
                    if (blurTarget) {
                        blurTarget.css('-webkit-filter', initialWekbitFilter || 'none');
                        blurTarget.css('filter', initalFilter || 'none');
                    }
                });
            },
        };
    },
]);
