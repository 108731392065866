// FIXME: https://trello.com/c/OVFLbiZx/924-feat-add-localization-support-for-countries-dropdowns
const countries = [
    {
        value: '',
        telPrefix: '',
    },
    {
        value: 'AF',
        telPrefix: '+93',
    },
    {
        value: 'AL',
        telPrefix: '+355',
    },
    {
        value: 'DZ',
        telPrefix: '+213',
    },
    {
        value: 'AD',
        telPrefix: '+376',
    },
    {
        value: 'AO',
        telPrefix: '+244',
    },
    {
        value: 'AG',
        telPrefix: '+1',
    },
    {
        value: 'AR',
        telPrefix: '+54',
    },
    {
        value: 'AM',
        telPrefix: '+374',
    },
    {
        value: 'AU',
        telPrefix: '+61',
    },
    {
        value: 'AT',
        telPrefix: '+43',
    },
    {
        value: 'AZ',
        telPrefix: '+994',
    },
    {
        value: 'BS',
        telPrefix: '+1',
    },
    {
        value: 'BH',
        telPrefix: '+973',
    },
    {
        value: 'BD',
        telPrefix: '+880',
    },
    {
        value: 'BB',
        telPrefix: '+1',
    },
    {
        value: 'BY',
        telPrefix: '+375',
    },
    {
        value: 'BE',
        telPrefix: '+32',
    },
    {
        value: 'BZ',
        telPrefix: '+501',
    },
    {
        value: 'BJ',
        telPrefix: '+229',
    },
    {
        value: 'BT',
        telPrefix: '+975',
    },
    {
        value: 'BO',
        telPrefix: '+591',
    },
    {
        value: 'BA',
        telPrefix: '+387',
    },
    {
        value: 'BW',
        telPrefix: '+267',
    },
    {
        value: 'BR',
        telPrefix: '+55',
    },
    {
        value: 'BN',
        telPrefix: '+673',
    },
    {
        value: 'BG',
        telPrefix: '+359',
    },
    {
        value: 'BF',
        telPrefix: '+226',
    },
    {
        value: 'BI',
        telPrefix: '+257',
    },
    {
        value: 'BQ',
        telPrefix: '+599',
    },
    {
        value: 'KH',
        telPrefix: '+855',
    },
    {
        value: 'CM',
        telPrefix: '+237',
    },
    {
        value: 'CA',
        telPrefix: '+1',
    },
    {
        value: 'CV',
        telPrefix: '+238',
    },
    {
        value: 'CF',
        telPrefix: '+236',
    },
    {
        value: 'TD',
        telPrefix: '+235',
    },
    {
        value: 'CL',
        telPrefix: '+56',
    },
    {
        value: 'CN',
        telPrefix: '+86',
    },
    {
        value: 'CO',
        telPrefix: '+57',
    },
    {
        value: 'KM',
        telPrefix: '+269',
    },
    {
        value: 'CD',
        telPrefix: '+243',
    },
    {
        value: 'CG',
        telPrefix: '+242',
    },
    {
        value: 'CR',
        telPrefix: '+506',
    },
    {
        value: 'CI',
        telPrefix: '+225',
    },
    {
        value: 'HR',
        telPrefix: '+385',
    },
    {
        value: 'CU',
        telPrefix: '+53',
    },
    {
        value: 'CY',
        telPrefix: '+357',
    },
    {
        value: 'CZ',
        telPrefix: '+420',
    },
    {
        value: 'DK',
        telPrefix: '+45',
    },
    {
        value: 'DJ',
        telPrefix: '+253',
    },
    {
        value: 'DM',
        telPrefix: '+1',
    },
    {
        value: 'DO',
        telPrefix: '+1',
    },
    {
        value: 'EC',
        telPrefix: '+593',
    },
    {
        value: 'EG',
        telPrefix: '+20',
    },
    {
        value: 'SV',
        telPrefix: '+503',
    },
    {
        value: 'GQ',
        telPrefix: '+240',
    },
    {
        value: 'ER',
        telPrefix: '+291',
    },
    {
        value: 'EE',
        telPrefix: '+372',
    },
    {
        value: 'ET',
        telPrefix: '+251',
    },
    {
        value: 'FJ',
        telPrefix: '+679',
    },
    {
        value: 'FI',
        telPrefix: '+358',
    },
    {
        value: 'FR',
        telPrefix: '+33',
    },
    {
        value: 'GA',
        telPrefix: '+241',
    },
    {
        value: 'GM',
        telPrefix: '+220',
    },
    {
        value: 'GE',
        telPrefix: '+995',
    },
    {
        value: 'DE',
        telPrefix: '+49',
    },
    {
        value: 'GH',
        telPrefix: '+233',
    },
    {
        value: 'GR',
        telPrefix: '+30',
    },
    {
        value: 'GD',
        telPrefix: '+1',
    },
    {
        value: 'GT',
        telPrefix: '+502',
    },
    {
        value: 'GN',
        telPrefix: '+224',
    },
    {
        value: 'GW',
        telPrefix: '+245',
    },
    {
        value: 'GY',
        telPrefix: '+592',
    },
    {
        value: 'HT',
        telPrefix: '+509',
    },
    {
        value: 'HN',
        telPrefix: '+504',
    },
    {
        value: 'HU',
        telPrefix: '+36',
    },
    {
        value: 'IS',
        telPrefix: '+354',
    },
    {
        value: 'IN',
        telPrefix: '+91',
    },
    {
        value: 'ID',
        telPrefix: '+62',
    },
    {
        value: 'IR',
        telPrefix: '+98',
    },
    {
        value: 'IQ',
        telPrefix: '+964',
    },
    {
        value: 'IE',
        telPrefix: '+353',
    },
    {
        value: 'IL',
        telPrefix: '+972',
    },
    {
        value: 'IT',
        telPrefix: '+39',
    },
    {
        value: 'JM',
        telPrefix: '+1',
    },
    {
        value: 'JP',
        telPrefix: '+81',
    },
    {
        value: 'JO',
        telPrefix: '+962',
    },
    {
        value: 'KZ',
        telPrefix: '+7',
    },
    {
        value: 'KE',
        telPrefix: '+254',
    },
    {
        value: 'KI',
        telPrefix: '+686',
    },
    {
        value: 'KP',
        telPrefix: '+850',
    },
    {
        value: 'KR',
        telPrefix: '+82',
    },
    {
        value: 'KW',
        telPrefix: '+965',
    },
    {
        value: 'KG',
        telPrefix: '+996',
    },
    {
        value: 'LA',
        telPrefix: '+856',
    },
    {
        value: 'LV',
        telPrefix: '+371',
    },
    {
        value: 'LB',
        telPrefix: '+961',
    },
    {
        value: 'LS',
        telPrefix: '+266',
    },
    {
        value: 'LR',
        telPrefix: '+231',
    },
    {
        value: 'LY',
        telPrefix: '+218',
    },
    {
        value: 'LI',
        telPrefix: '+423',
    },
    {
        value: 'LT',
        telPrefix: '+370',
    },
    {
        value: 'LU',
        telPrefix: '+352',
    },
    {
        value: 'MK',
        telPrefix: '+389',
    },
    {
        value: 'MG',
        telPrefix: '+261',
    },
    {
        value: 'MW',
        telPrefix: '+265',
    },
    {
        value: 'MY',
        telPrefix: '+60',
    },
    {
        value: 'MV',
        telPrefix: '+960',
    },
    {
        value: 'ML',
        telPrefix: '+223',
    },
    {
        value: 'MT',
        telPrefix: '+356',
    },
    {
        value: 'MH',
        telPrefix: '+692',
    },
    {
        value: 'MR',
        telPrefix: '+222',
    },
    {
        value: 'MU',
        telPrefix: '+230',
    },
    {
        value: 'MX',
        telPrefix: '+52',
    },
    {
        value: 'FM',
        telPrefix: '+691',
    },
    {
        value: 'MD',
        telPrefix: '+373',
    },
    {
        value: 'MC',
        telPrefix: '+377',
    },
    {
        value: 'MN',
        telPrefix: '+976',
    },
    {
        value: 'ME',
        telPrefix: '+382',
    },
    {
        value: 'MA',
        telPrefix: '+212',
    },
    {
        value: 'MZ',
        telPrefix: '+258',
    },
    {
        value: 'MM',
        telPrefix: '+95',
    },
    {
        value: 'NA',
        telPrefix: '+264',
    },
    {
        value: 'NR',
        telPrefix: '+674',
    },
    {
        value: 'NP',
        telPrefix: '+977',
    },
    {
        value: 'NL',
        telPrefix: '+31',
    },
    {
        value: 'NZ',
        telPrefix: '+64',
    },
    {
        value: 'NI',
        telPrefix: '+505',
    },
    {
        value: 'NE',
        telPrefix: '+227',
    },
    {
        value: 'NG',
        telPrefix: '+234',
    },
    {
        value: 'NO',
        telPrefix: '+47',
    },
    {
        value: 'OM',
        telPrefix: '+968',
    },
    {
        value: 'PK',
        telPrefix: '+92',
    },
    {
        value: 'PW',
        telPrefix: '+680',
    },
    {
        value: 'PA',
        telPrefix: '+507',
    },
    {
        value: 'PG',
        telPrefix: '+675',
    },
    {
        value: 'PY',
        telPrefix: '+595',
    },
    {
        value: 'PE',
        telPrefix: '+51',
    },
    {
        value: 'PH',
        telPrefix: '+63',
    },
    {
        value: 'PL',
        telPrefix: '+48',
    },
    {
        value: 'PT',
        telPrefix: '+351',
    },
    {
        value: 'QA',
        telPrefix: '+974',
    },
    {
        value: 'RO',
        telPrefix: '+40',
    },
    {
        value: 'RU',
        telPrefix: '+7',
    },
    {
        value: 'RW',
        telPrefix: '+250',
    },
    {
        value: 'KN',
        telPrefix: '+1',
    },
    {
        value: 'LC',
        telPrefix: '+1',
    },
    {
        value: 'VC',
        telPrefix: '+1',
    },
    {
        value: 'WS',
        telPrefix: '+685',
    },
    {
        value: 'SM',
        telPrefix: '+378',
    },
    {
        value: 'ST',
        telPrefix: '+239',
    },
    {
        value: 'SA',
        telPrefix: '+966',
    },
    {
        value: 'SN',
        telPrefix: '+221',
    },
    {
        value: 'RS',
        telPrefix: '+381',
    },
    {
        value: 'SC',
        telPrefix: '+248',
    },
    {
        value: 'SL',
        telPrefix: '+232',
    },
    {
        value: 'SG',
        telPrefix: '+65',
    },
    {
        value: 'SK',
        telPrefix: '+421',
    },
    {
        value: 'SI',
        telPrefix: '+386',
    },
    {
        value: 'SB',
        telPrefix: '+677',
    },
    {
        value: 'SO',
        telPrefix: '+252',
    },
    {
        value: 'ZA',
        telPrefix: '+27',
    },
    {
        value: 'SS',
        telPrefix: '+211',
    },
    {
        value: 'ES',
        telPrefix: '+34',
    },
    {
        value: 'LK',
        telPrefix: '+94',
    },
    {
        value: 'SD',
        telPrefix: '+249',
    },
    {
        value: 'SR',
        telPrefix: '+597',
    },
    {
        value: 'SZ',
        telPrefix: '+268',
    },
    {
        value: 'SE',
        telPrefix: '+46',
    },
    {
        value: 'CH',
        telPrefix: '+41',
    },
    {
        value: 'SY',
        telPrefix: '+963',
    },
    {
        value: 'TJ',
        telPrefix: '+992',
    },
    {
        value: 'TZ',
        telPrefix: '+255',
    },
    {
        value: 'TH',
        telPrefix: '+66',
    },
    {
        value: 'TL',
        telPrefix: '+670',
    },
    {
        value: 'TG',
        telPrefix: '+228',
    },
    {
        value: 'TO',
        telPrefix: '+676',
    },
    {
        value: 'TT',
        telPrefix: '+1',
    },
    {
        value: 'TN',
        telPrefix: '+216',
    },
    {
        value: 'TR',
        telPrefix: '+90',
    },
    {
        value: 'TM',
        telPrefix: '+993',
    },
    {
        value: 'TV',
        telPrefix: '+688',
    },
    {
        value: 'UG',
        telPrefix: '+256',
    },
    {
        value: 'UA',
        telPrefix: '+380',
    },
    {
        value: 'AE',
        telPrefix: '+971',
    },
    {
        value: 'GB',
        telPrefix: '+44',
    },
    {
        value: 'US',
        telPrefix: '+1',
    },
    {
        value: 'UY',
        telPrefix: '+598',
    },
    {
        value: 'UZ',
        telPrefix: '+998',
    },
    {
        value: 'VU',
        telPrefix: '+678',
    },
    {
        value: 'VA',
        telPrefix: '+379',
    },
    {
        value: 'VE',
        telPrefix: '+58',
    },
    {
        value: 'VN',
        telPrefix: '+84',
    },
    {
        value: 'YE',
        telPrefix: '+967',
    },
    {
        value: 'ZM',
        telPrefix: '+260',
    },
    {
        value: 'ZW',
        telPrefix: '+263',
    },
    {
        value: 'TW',
        telPrefix: '+886',
    },
    {
        value: 'CX',
        telPrefix: '+61',
    },
    {
        value: 'CC',
        telPrefix: '+61',
    },
    {
        value: 'NF',
        telPrefix: '+672',
    },
    {
        value: 'NC',
        telPrefix: '+687',
    },
    {
        value: 'PF',
        telPrefix: '+689',
    },
    {
        value: 'YT',
        telPrefix: '+269',
    },
    {
        value: 'PM',
        telPrefix: '+508',
    },
    {
        value: 'WF',
        telPrefix: '+681',
    },
    {
        value: 'CK',
        telPrefix: '+682',
    },
    {
        value: 'NU',
        telPrefix: '+683',
    },
    {
        value: 'TK',
        telPrefix: '+690',
    },
    {
        value: 'GG',
        telPrefix: '+44',
    },
    {
        value: 'IM',
        telPrefix: '+44',
    },
    {
        value: 'JE',
        telPrefix: '+44',
    },
    {
        value: 'AI',
        telPrefix: '+1',
    },
    {
        value: 'BM',
        telPrefix: '+1',
    },
    {
        value: 'IO',
        telPrefix: '+246',
    },
    {
        value: 'VG',
        telPrefix: '+1',
    },
    {
        value: 'KY',
        telPrefix: '+1',
    },
    {
        value: 'FK',
        telPrefix: '+500',
    },
    {
        value: 'GI',
        telPrefix: '+350',
    },
    {
        value: 'MS',
        telPrefix: '+1',
    },
    {
        value: 'SH',
        telPrefix: '+290',
    },
    {
        value: 'TC',
        telPrefix: '+1',
    },
    {
        value: 'MP',
        telPrefix: '+1',
    },
    {
        value: 'PR',
        telPrefix: '+1',
    },
    {
        value: 'AS',
        telPrefix: '+1',
    },
    {
        value: 'GU',
        telPrefix: '+1',
    },
    {
        value: 'VI',
        telPrefix: '+1',
    },
    {
        value: 'HK',
        telPrefix: '+852',
    },
    {
        value: 'MO',
        telPrefix: '+853',
    },
    {
        value: 'FO',
        telPrefix: '+298',
    },
    {
        value: 'GL',
        telPrefix: '+299',
    },
    {
        value: 'GF',
        telPrefix: '+594',
    },
    {
        value: 'GP',
        telPrefix: '+590',
    },
    {
        value: 'MQ',
        telPrefix: '+596',
    },
    {
        value: 'RE',
        telPrefix: '+262',
    },
    {
        value: 'AX',
        telPrefix: '+358',
    },
    {
        value: 'AW',
        telPrefix: '+297',
    },
    {
        value: 'SJ',
        telPrefix: '+47',
    },
    {
        value: 'AC',
        telPrefix: '+247',
    },
    {
        value: 'TA',
        telPrefix: '+290',
    },
    {
        value: 'PS',
        telPrefix: '+970',
    },
    {
        value: 'XK',
        telPrefix: '+383',
    },
];

export default countries;
