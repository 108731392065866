import { type StreamIguanaObject } from 'Lessons';
import { generateGuid } from 'guid';
import ClientStorage from 'ClientStorage';

export function getOrGenerateExamVerificationSessionId(stream: StreamIguanaObject): string {
    const clientStorageKey = `ExamVerificationSession/${stream.localePackId}`;
    const existingClientStorageValue = ClientStorage.getItem(clientStorageKey);

    // If we have an existing "session" in ClientStorage, we want to use it always.
    // If the ExamVerification record(s) corresponding to this "session"'s ID is expired,
    // we'll force the user into BioSig to generate a new one (or consume a manual admin
    // verification that is, as of yet, unclaimed).
    if (existingClientStorageValue) {
        return existingClientStorageValue;
    }

    const guid = generateGuid();
    ClientStorage.setItem(clientStorageKey, guid);
    return guid;
}
