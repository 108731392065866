import 'Translation/angularModule';
import countries from 'Countries/countries';

export default angular.module('FrontRoyal.Countries', ['Translation']).factory('LocalizedCountries', [
    '$injector',
    $injector => {
        const TranslationHelper = $injector.get('TranslationHelper');
        const $translate = $injector.get('$translate');
        const translationHelper = new TranslationHelper('countries.country');

        const localized = {};

        return {
            getForCurrentLocale() {
                const currentLang = $translate.use(false);
                if (!localized[currentLang]) {
                    localized[currentLang] = countries
                        .map(country => ({
                            value: country.value,
                            text: country.value ? translationHelper.get(country.value) : '',
                            telPrefix: country.telPrefix,
                        }))
                        .sort((a, b) => a.text.localeCompare(b.text, currentLang));
                }
                return localized[currentLang];
            },
        };
    },
]);
