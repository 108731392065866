import 'bootstrap-sass/assets/javascripts/bootstrap/modal';
import 'SafeApply/angularModule';
import 'EventLogger/angularModule';
import 'Translation/angularModule';
import 'FrontRoyalSpinner/angularModule';
import confirmByTypingTemplate from 'DialogModal/angularModule/views/confirm_by_typing_modal.html';
import reloadWindow from 'reloadWindow';

const dialogModalModule = angular
    .module('DialogModal', ['safeApply', 'EventLogger', 'Translation', 'FrontRoyalSpinner'])
    .factory('DialogModal', [
        '$injector',
        $injector => {
            const $compile = $injector.get('$compile');
            const $rootScope = $injector.get('$rootScope');
            const EventLogger = $injector.get('EventLogger');
            const $translate = $injector.get('$translate');
            const $sce = $injector.get('$sce');

            const DialogModal = {
                alert(options) {
                    const newScope = $rootScope.$new();

                    this._addOptionsToScope(newScope, options);

                    // Not sure if we really want to force this here, but
                    // I don't know if there are any practical cases when
                    // it matters right now anyway
                    this.removeAlerts(true);
                    this.blurTarget = $(options.blurTargetSelector);

                    // build out a container and add it to the body
                    const bodyElem = $('body');
                    const htmlText = '<dialog-modal-alert></dialog-modal-alert>';
                    const modalContainer = $(htmlText).append(options.content).prependTo(bodyElem);

                    // I added this option because I had a case where I logged a fatal
                    // error (see showFatalError below) but then it was removed when something
                    // else causes the student-dashboard scope to get destroyed.  I actually
                    // ended up fixing the issue that caused the scope to get destroyed, but this
                    // still seems like a good idea.  If we log a fatal error, we don't want
                    // anything to remove it.  We want the user to click to reload the page.
                    if (options.indestructible) {
                        modalContainer.attr('indestructible', 'true');
                    }

                    EventLogger.log(
                        'modal_dialog:alert',
                        {
                            label: newScope.title || options.content,
                        },
                        {
                            segmentio: false,
                        },
                    );

                    // blur app if option is true
                    if (this.blurTarget) {
                        this.addBlur();
                    }

                    // compile on given scope
                    $compile(modalContainer)(newScope);

                    // add the body class
                    bodyElem.addClass('dialog-modal');

                    // cleanup any lingering visual elements on scope destroy
                    function destroyCallback() {
                        this.removeAlerts();
                    }
                    newScope.$on('$destroy', destroyCallback.bind(this));
                },

                /*
                Creates a confirmation dialog modal alert that cannot be closed until either the
                cancel or confirm action has been taken.
                @param options - an object whose properties control various aspects of the confirmation modal's behavior and UI.

                    Configurable properties on the 'options' param

                        cancelButtonText - the text to display on the cancel button
                        cancelCallback - a custom handler function for the cancel action
                        confirmButtonText - the text to display on the confirm button
                        confirmCallback - a custom handler function for the confirm action
                        scope - an object whose properties get applied to the dialog modal alert's scope (unless the options
                            param has matching properties, in which case the properties on the options param will override the
                            properties configured on the options.scope object - see DialogModal#_addOptionsToScope for more info
                            and for a list of the properties that can be configured on the scope object)
                        showThanks - a boolean that controls whether a "Thank you" message should replace the contents
                            of the confirmation modal for a short period of time after the user has confirmed their action.
                        text - the confirmation message that initially gets displayed in the modal upon request for confirmation
                            of the user's action
                        confirmDeleteByTyping: text to ask the user to confirm a delete by typing; if provided, `showThanks` and
                            `text` options are ignored, and `confirmDeleteThing` is used in resulting message.
                        confirmDeleteThing: 'thing' to confirm deletion of, e.g.: 'are you sure you wish to delete {{thing}}?'
                        hideCancelButton - a boolean that controls whether the cancel button is displayed. Best to combine with
                            options "buttonContainerClass: center" and "confirmButtonClass: no-float"
            */
                confirm(options = {}) {
                    const $timeout = $injector.get('$timeout');
                    const $q = $injector.get('$q');
                    const newScope = $rootScope.$new();
                    const spinnerProxy = { showSpinner: false };

                    // modal action click handler that runs the requested callback if present.
                    // If the callback returns a promise, then the modal will only be hidden when
                    // the promise resolves. The modal will remain if the promise rejects. If there is no
                    // callback, or if the callback does not return a promise, then the modal will always be hidden.
                    // (To see an example of this being used with a promise, see _saveLessonAndStreamProgress)
                    function onActionClick(callback) {
                        const delay = options.showThanks && callback === 'confirmCallback' ? 2500 : 0;
                        $timeout(() => {
                            let promise = $q.when();

                            // In the case where the callback returns a promise, both
                            // callbackSuccess and minTimeoutComplete will be overridden
                            let callbackSuccess = true;
                            let minTimeout = 0;

                            spinnerProxy.showSpinner = true;
                            // execute the callback if it's available
                            if (options[callback]) {
                                const result = options[callback]();
                                if (result?.then) {
                                    minTimeout = 500;
                                    callbackSuccess = null;
                                    promise = result
                                        .then(() => {
                                            callbackSuccess = true;
                                        })
                                        .catch(() => {
                                            callbackSuccess = false;
                                        });
                                }
                            }

                            // The point of minTimeout is to avoid an odd experience if the promise is rejected
                            // very fast. If there is no noticeable spinner, then it just appears like the button
                            // didn't work at all. If we ensure that a spinner shows for at least 500ms, then hopefully
                            // it's clear that whatever caused this modal to show up has not yet been resolved.
                            let minTimeoutComplete = false;
                            const minSpinnerTimeout = $timeout(minTimeout).then(() => {
                                minTimeoutComplete = true;
                            });

                            // Since checkIfCallbackAndTimerComplete is going to be triggered twice, we need to
                            // track modalHidden so we don't hide modals twice in the case where the callback does
                            // not return a promise
                            let modalHidden = false;
                            function checkIfCallbackAndTimerComplete() {
                                // When the callback completes successfully, we hide the modal
                                // immediately (unless we've already hidden it)
                                if (callbackSuccess && !modalHidden) {
                                    // be sure to hide the last dialog-modal-alert modal since confirm
                                    // dialog modals should be inserted after all other dialog modal alerts
                                    // already in existence
                                    $('dialog-modal-alert:last-of-type .modal').modal('hide');
                                    modalHidden = true;
                                }

                                // When the callback completes unsuccessfully, we hide the modal only
                                // if we've reached the minTimeout, otherwise we wait for the minTimeout
                                if (minTimeoutComplete && callbackSuccess === false) {
                                    spinnerProxy.showSpinner = false;
                                }
                            }

                            promise.then(checkIfCallbackAndTimerComplete);
                            minSpinnerTimeout.then(checkIfCallbackAndTimerComplete);
                        }, delay);
                    }

                    options.size = options.size || 'small';
                    options.hideCloseButton = true;
                    options.scope = {
                        text: $sce.trustAsHtml(options.text),
                        confirmDeleteByTyping: options.confirmDeleteByTyping,
                        confirmDeleteThing: options.confirmDeleteThing,
                        cancelButtonText:
                            options.cancelButtonText ||
                            $translate.instant('dialog_modals.dialog_modal_alert.confirm_action_cancel'),
                        confirmButtonText:
                            options.confirmButtonText ||
                            $translate.instant('dialog_modals.dialog_modal_alert.confirm_action_ok'),
                        buttonContainerClass: options.buttonContainerClass,
                        cancelButtonClass: options.cancelButtonClass,
                        confirmButtonClass: options.confirmButtonClass,
                        onActionClick,
                        spinnerProxy,
                    };

                    if (options.confirmDeleteByTyping) {
                        options.content = '<confirm-by-typing-modal></confirm-by-typing-modal>';
                        options.size = 'medium';
                        options.title = $translate.instant('dialog_modals.dialog_modal_alert.confirm_by_typing_title');
                    } else {
                        options.content = `<p class="message center" ng-if="!showThanks" ng-bind-html="text"></p>
                            <span class="center thanks" ng-if="showThanks" translate-once="dialog_modals.dialog_modal_alert.confirm_thanks"></span>
                            <div ng-if="!showThanks && !spinnerProxy.showSpinner" class="{{::buttonContainerClass}}"><button class="modal-action-button inline small confirm cancel {{::cancelButtonClass}}" ng-if="!hideCancelButton" ng-click="onActionClick('cancelCallback')">{{::cancelButtonText}}</button>
                            <button class="modal-action-button inline small confirm ok {{::confirmButtonClass}}" ng-click="$parent.showThanks = ${options.showThanks} && true; onActionClick('confirmCallback')">{{::confirmButtonText}}</button></div>
                            <front-royal-spinner ng-if="spinnerProxy.showSpinner" class='no-delay' ></front-royal-spinner>`;
                    }
                    this._addOptionsToScope(newScope, options);

                    // build out a container and add it to the body
                    const htmlText = '<dialog-modal-alert></dialog-modal-alert>';

                    let modalContainer;
                    if ($('dialog-modal-alert').length > 0) {
                        // If one or more dialog-modal-alerts already exist, we need to insert this
                        // one AFTER the last dialog-modal-alert element.
                        modalContainer = $(htmlText)
                            .append(options.content)
                            .insertAfter($('dialog-modal-alert:last-of-type'));
                    } else {
                        const bodyElem = $('body');
                        modalContainer = $(htmlText).append(options.content).prependTo(bodyElem);
                        // add the body class
                        bodyElem.addClass('dialog-modal');
                    }

                    EventLogger.log(
                        'modal_dialog:confirm',
                        {
                            label: newScope.title || options.content,
                        },
                        {
                            segmentio: false,
                        },
                    );

                    // compile on given scope
                    $compile(modalContainer)(newScope);
                },

                showFatalError() {
                    return this.alert({
                        content:
                            `<p class="message" translate-once="front_royal_api_error_handler.api_error_handler.something_went_wrong"></p>` +
                            '<button class="modal-action-button" ng-click="reload()" translate-once="front_royal_api_error_handler.fatal.continue"></button>',
                        size: 'small',
                        hideCloseButton: true,
                        closeOnClick: false,
                        indestructible: true,
                        scope: {
                            reload() {
                                reloadWindow({ label: 'dialog_modal:show_fatal_error', EventLogger });
                            },
                        },
                        blurTargetSelector: 'div[ng-controller]',
                    });
                },

                // this is immediate
                removeAlerts(force) {
                    if ($('dialog-modal-alert[indestructible]').length > 0 && !force) {
                        return;
                    }
                    $('.modal').modal('hide');
                    $('.modal-backdrop').remove();
                    $('dialog-modal-alert').remove();
                    this.removeBlur();
                    $('body').removeClass('dialog-modal');
                },

                // this does it all pretty like, with a fade and a slide
                hideAlerts() {
                    if ($('dialog-modal-alert[indestructible]').length > 0) {
                        return;
                    }
                    $('.modal').modal('hide');
                    this.removeBlur();
                },

                addBlur() {
                    if (!this.blurTarget) {
                        return;
                    }
                    this.blurTarget.css('-webkit-filter', 'blur(3px)');
                    this.blurTarget.css('filter', 'blur(3px)');
                },

                removeBlur() {
                    if (!this.blurTarget) {
                        return;
                    }
                    this.blurTarget.css('-webkit-filter', 'none');
                    this.blurTarget.css('filter', 'none');
                },

                _addOptionsToScope(scope, options) {
                    options = angular.extend(
                        {
                            content: '<div></div>', // string or element
                            scope: {}, // properties to be copied onto the new scope
                            title: undefined,
                            close: undefined,
                            size: 'normal', // can be set to 'large' or 'fullscreen' or 'small'
                            classes: [],
                            closeOnClick: false,
                            closeOnOutsideClick: false,
                            hideCloseButton: false,
                            animated: true,
                            blurTargetSelector: undefined,
                            hideCancelButton: false,
                        },
                        options,
                    );

                    angular.extend(scope, options.scope);
                    angular.extend(scope, {
                        title: options.title,
                        size: options.size,
                        close: options.close,
                        classes: options.classes,
                        closeOnClick: options.closeOnClick,
                        closeOnOutsideClick: options.closeOnOutsideClick,
                        hideCloseButton: options.hideCloseButton,
                        animated: options.animated,
                        blurTargetSelector: options.blur,
                        hideCancelButton: options.hideCancelButton,
                    });
                },
            };

            return DialogModal;
        },
    ]);

dialogModalModule.directive('confirmByTypingModal', [
    '$injector',
    () => ({
        restrict: 'E',
        template: confirmByTypingTemplate,
        link() {},
    }),
]);

export default dialogModalModule;
