import angularModule from 'DragAndDrop/angularModule/scripts/lib/drag_and_drop';
/*
    included in directives that add droppable functionality (current droppable and dragsort)
*/
angularModule.factory('DroppableDirHelper', [
    'AClassAbove',
    'DragAndDrop.EventListener',
    (AClassAbove, EventListener) => {
        const DroppableHelper = AClassAbove.subclass(() => ({
            initialize(element, scope, attrs) {
                function acceptDrag(e) {
                    if (attrs.accept) {
                        const shouldAccept = scope.$eval(attrs.accept, {
                            $event: e,
                        });
                        return shouldAccept;
                    }
                    return true;
                }

                const listeners = [];

                listeners.push(
                    new EventListener.DragOver(null, element, function (e) {
                        e.dataTransfer.dropEffect = 'move';
                        // allows us to drop. FIXME: make configurable
                        if (acceptDrag(e)) {
                            if (e.preventDefault) {
                                e.preventDefault();
                            }
                            this.classList.add('over');
                        }
                        return false;
                    }),
                );

                EventListener.DragOver.$observe(null, element, scope, attrs);

                listeners.push(
                    new EventListener.DragEnter(null, element, function (e) {
                        if (acceptDrag(e)) {
                            this.classList.add('over');
                        }
                        return false;
                    }),
                );

                listeners.push(
                    new EventListener.DragLeave(null, element, function () {
                        this.classList.remove('over');
                        return false;
                    }),
                );

                listeners.push(
                    new EventListener.Drop(null, element, function () {
                        this.classList.remove('over');
                        return false;
                    }),
                );

                // watch the attributes for changes in user-defined callbacks
                EventListener.Drop.$observe(null, element, scope, attrs);
                EventListener.DragEnter.$observe(null, element, scope, attrs);
                EventListener.DragLeave.$observe(null, element, scope, attrs);
                EventListener.DragOver.$observe(null, element, scope, attrs);

                scope.$on('$destroy', () => {
                    EventListener.$stopObserving(attrs);
                    angular.forEach(listeners, listener => {
                        listener.destroy();
                    });
                });
            },
        }));

        return DroppableHelper;
    },
]);
