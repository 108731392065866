/* eslint-disable no-plusplus */
import { generateGuid } from 'guid';

const dynamicNode = [
    '$injector',
    $injector => {
        const $animate = $injector.get('$animate');
        const $compile = $injector.get('$compile');
        const $window = $injector.get('$window');

        return {
            multiElement: true,
            transclude: 'element',
            priority: 500,
            terminal: true,
            restrict: 'A',
            // $$tlb: true,
            scope: true,
            link($scope, $element, $attr, ctrl, $transclude) {
                let block;
                let childScope;
                let previousElements;
                let currentRunningAnimation;
                let buildId;

                $scope._cleanupAfterEnterListener = () => {};

                const slice = [].slice;

                /**
                 * Return the DOM siblings between the first and last node in the given array.
                 * @param {Array} array like object
                 * @returns {Array} the inputted object or a jqLite collection containing the nodes
                 */
                function getBlockNodes(nodes) {
                    // TODO: https://trello.com/c/w32rWa9i/908-perf-refactor-getblocknodes-in-dynamic-node-dir-to-manipulate-just-the-nodes-array
                    let node = nodes[0];
                    const endNode = nodes[nodes.length - 1];
                    let blockNodes;

                    // eslint-disable-next-line no-cond-assign
                    for (let i = 1; node !== endNode && (node = node.nextSibling); i++) {
                        if (blockNodes || nodes[i] !== node) {
                            if (!blockNodes) {
                                blockNodes = $(slice.call(nodes, 0, i));
                            }
                            blockNodes.push(node);
                        }
                    }

                    return blockNodes || nodes;
                }

                function cleanupPreviousElements() {
                    if (previousElements) {
                        previousElements.remove();
                        previousElements = null;
                    }
                }

                function remove() {
                    if (childScope) {
                        childScope.$destroy();
                        childScope = null;
                    }

                    if (block) {
                        previousElements = getBlockNodes(block.clone);

                        // NOTE: at some point, we might want to implement `afterLeave` functionality here

                        // extremely hard to test $animate promises in specs. see below!
                        if ($scope.animateLeave && !$window.RUNNING_IN_TEST_MODE) {
                            currentRunningAnimation = $animate.leave(previousElements).then(() => {
                                cleanupPreviousElements();
                                currentRunningAnimation = undefined;
                            });
                        } else {
                            cleanupPreviousElements();
                        }

                        block = null;
                    }
                }

                // rebuild and recompile element content
                function rebuildElement(newScope) {
                    const nodeName = $scope.dynamicNode;
                    const newElement = $(`<${nodeName}>`);

                    angular.forEach($attr, (value, name) => {
                        const _name = name.snakeCase();

                        if (
                            _name.slice(0, 1) !== '$' &&
                            _name !== 'dynamic-node' &&
                            _name !== 'after-enter' &&
                            _name !== 'observe'
                        ) {
                            newElement.attr(_name, value);
                        }
                    });
                    $compile(newElement)(newScope);
                    return newElement;
                }

                try {
                    Object.defineProperty($scope, 'dynamicNode', {
                        get() {
                            return $scope.$eval($attr.dynamicNode);
                        },
                    });
                } catch (e) {
                    throw new Error(
                        `${e.message} - You may be trying to use dynamic-node at the top level of a template injected by dynamic-node.`,
                    );
                }

                Object.defineProperty($scope, 'animateLeave', {
                    get() {
                        return $scope.$eval($attr.animateLeave);
                    },
                });

                Object.defineProperty($scope, 'observed', {
                    get() {
                        if (!$attr.observe) {
                            return [];
                        }
                        const arr = [];
                        angular.forEach($attr.observe.split(','), exp => {
                            arr.push($scope.$eval(exp));
                        });
                        return arr;
                    },
                });

                Object.defineProperty($scope, 'rebuildOn', {
                    get() {
                        return this.observed.concat([this.dynamicNode]);
                    },
                });

                $scope.$watchCollection('rebuildOn', () => {
                    buildId = generateGuid();

                    if (angular.isDefined($attr.ngIf)) {
                        throw new Error(
                            `dynamic-node directive cannot be used with ng-if: dynamic-node=${$attr.dynamicNode}`,
                        );
                    }

                    remove();

                    if ($scope._cleanupAfterEnterListener) {
                        $scope._cleanupAfterEnterListener();
                    }

                    if ($scope.dynamicNode) {
                        $transclude((clone, newScope) => {
                            childScope = newScope;

                            clone.length = 0; // this will clear but retain ref
                            const el = rebuildElement(newScope);
                            clone[clone.length++] = el[0];
                            clone[clone.length++] = document.createComment(` end dynamicNode: ${$attr.dynamicNode} `);

                            // Note: We only need the first/last node of the cloned nodes.
                            // However, we need to keep the reference to the jqlite wrapper as it might be changed later
                            // by a directive with templateUrl when its template arrives.
                            block = {
                                clone,
                            };

                            // handle registration / de-registration of enter callback
                            if ($attr.afterEnter) {
                                const callback = (element, phase) => {
                                    if (phase === 'close') {
                                        $scope.$eval($attr.afterEnter, {
                                            $scope: childScope,
                                        });
                                    }
                                };
                                // var animateElement = $(els);
                                $animate.on('enter', clone, callback);
                                $scope._cleanupAfterEnterListener = () => {
                                    $animate.off('enter', clone, callback);
                                };
                            }

                            // kick off animation
                            // NOTE: as of 1.4-rc0, we need to ensure the existing animation if running
                            // completes before beginning the enter animation. however, I can't find a reliable
                            // way of testing this in specs during multiple rebuilds
                            if (currentRunningAnimation && !$window.RUNNING_IN_TEST_MODE) {
                                const buildIdWhenWeStartedWaiting = buildId;
                                currentRunningAnimation.then(() => {
                                    // if the element has been rebuilt since we
                                    // started waiting, then do not start the animation
                                    // for the old element
                                    if (buildIdWhenWeStartedWaiting !== buildId) {
                                        return;
                                    }
                                    $animate.enter(clone, $element.parent());
                                });
                            } else {
                                $animate.enter(clone, $element.parent());
                            }
                        });
                    }
                });

                $scope.$on('$destroy', () => {
                    $scope._cleanupAfterEnterListener();
                    if (childScope) {
                        childScope.$destroy();
                    }
                });
            },
        };
    },
];

export default angular.module('dynamicNode', []);
angular.module('dynamicNode').directive('dynamicNode', dynamicNode);
