import { type StreamIguanaObject } from 'Lessons';
import { type CurrentUserIguanaObject, getRelevantUserProgramStates } from 'Users';
import { getStreamIsInCurriculum, requiresProctoring } from 'Cohorts';
import { type Config as FrontRoyalConfigType } from 'FrontRoyalConfig';
import { examVerificationRecordForStream } from './examVerificationRecordForStream';

export function mustVerifyIdentityToLaunchStream(
    user: CurrentUserIguanaObject | null,
    stream: StreamIguanaObject | null,
    frontRoyalConfig: FrontRoyalConfigType,
) {
    // Some Streams are made available to the public and don't require an authenticated user
    // in order to launch them.
    // Additionally, not all Lessons have a Stream associated with them.
    if (!user || !stream) {
        return false;
    }

    const programThatRequiresProctoring = getRelevantUserProgramStates(user).find(
        ups =>
            requiresProctoring(ups.cohort, frontRoyalConfig) &&
            getStreamIsInCurriculum(ups.cohort, stream.localePackId),
    );

    // None of the user's programs require proctoring, so consider any Stream this user wants to launch
    // as having a valid exam verification.
    if (!programThatRequiresProctoring) {
        return false;
    }
    // Stream is complete.
    if (stream.complete) {
        return false;
    }
    // Stream time limit exceeded.
    if (stream.msLeftInTimeLimit === 0) {
        return false;
    }
    // Stream is not an exam or a practice exam that explicitly requires proctoring
    if (!(stream.exam || stream.requires_proctoring)) {
        return false;
    }
    // The user has a valid ExamVerification record for this Stream
    if (examVerificationRecordForStream(user, stream)) {
        return false;
    }
    // The user must verify their identity.
    return true;
}
