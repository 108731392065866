
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.dismissable_message = window.Smartly.locales.modules.en.back_royal.dismissable_message || {};
window.Smartly.locales.modules.en.back_royal.dismissable_message = {...window.Smartly.locales.modules.en.back_royal.dismissable_message, ...{
    "close_message": "Close message"
}
}
    