import angularModule from 'EmailInput/angularModule/scripts/email_input_module';
import { isNonRelayEmail } from 'EmailInput';

angularModule.directive('emailValidation', [
    '$injector',
    $injector => ({
        require: 'ngModel',

        link(scope, element, attrs, modelCtrl) {
            const TranslationHelper = $injector.get('TranslationHelper');
            const translationHelper = new TranslationHelper('email_input.email_validation');
            const freeEmailDomains = $injector.get('freeEmailDomains');

            modelCtrl.$validators.validCharacters = (modelValue, viewValue) => {
                const value = modelValue || viewValue;
                const options = scope.$eval(attrs.emailValidation);
                const errors = options.errors;
                const requireCompanyEmail = options.requireCompanyEmail;
                const requireNonRelay = options.requireNonRelay;

                const _errors = [];
                if (requireCompanyEmail && freeEmailDomains.isFreeEmail(value)) {
                    _errors.push(translationHelper.get('please_use_company_email'));
                }

                if (requireNonRelay && value && isNonRelayEmail(value)) {
                    _errors.push(translationHelper.get('please_use_non_relay_email'));
                }

                // copied the regex from http://www.w3schools.com/jsref/prop_email_pattern.asp but changed {2,3}
                // at the end to {2,9} to make sure that long tope-level domains are supported
                if (value && !value.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$/i)) {
                    _errors.push(translationHelper.get('please_enter_valid_email'));
                }

                if (_.some(_errors)) {
                    if (errors) {
                        errors[attrs.name] = _errors.join(', ');
                    }
                    return false;
                }
                if (errors) {
                    delete errors[attrs.name];
                }
                return true;
            };
        },
    }),
]);
