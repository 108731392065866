// Dexie.maxKey does not work when using fake-indexed-db
// Dexie.maxKey looks like this:
/*
var maxString = String.fromCharCode(65535);
var maxKey = (function () { try {
    IDBKeyRange.only([[]]);
    return [[]];
}
catch (e) {
    return maxString;
} })();
*/

export const minKey = -Infinity;
export const maxKey = String.fromCharCode(65535);
