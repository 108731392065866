import angularModule from 'DragAndDrop/angularModule/scripts/lib/drag_and_drop';
import { generateGuid } from 'guid';

/*
    this class is basically just an object, but it has an id that can be added
    to the dataTransfer element used in drag and drop events.  That id can then
    be used later to look up the actual object.

    This is necessary because dataTransfer objects can only store strings, not
    complex objects.
*/
angularModule.factory('DragAndDrop.DragInfo', [
    'AClassAbove',
    '$timeout',
    (AClassAbove, $timeout) => {
        const DragInfo = AClassAbove.subclass(function () {
            this.extend({
                new(dragId) {
                    const instance = new this(dragId);
                    this.register(instance);
                    return instance;
                },

                register(instance) {
                    this.instances = this.instances || {};
                    this.instances[instance.dragId] = instance;
                },

                get(dragId) {
                    return this.instances && this.instances[dragId];
                },

                deregister(dragId) {
                    delete this.instances[dragId];
                },
            });

            return {
                initialize() {
                    this.dragId = generateGuid();
                },

                destroy() {
                    // wait to deregister the dragInfo to make sure anyone processing
                    // an event has access to it.  This is an issue if an event
                    // causes the dragged element to disappear from the dom and
                    // it's scope to be destroyed, while something else is paying
                    // attention to that event (like if something is being dragged
                    // from one list and dropped in another)
                    $timeout(() => {
                        DragInfo.deregister(this.dragId);
                    });
                },
            };
        });

        return DragInfo;
    },
]);
