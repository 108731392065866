import 'Lessons/angularModule';
import 'Navigation/angularModule';
import 'SearchEngineOptimization/angularModule';
import 'Translation/angularModule';
import 'Users/angularModule';

export default angular.module('FrontRoyal.ContentItem', [
    'AClassAbove',
    'Translation',
    'FrontRoyal.Lessons', // FIXME: UGH! - needs access to ComponentizedFixtures. refactor?
    'FrontRoyal.Navigation',
    'FrontRoyal.Users',
    'SiteMetadata',
]);
