import moment from 'moment-timezone';

const ADMIN_REF_TIMEZONE = 'America/Los_Angeles';

/*
    This method is useful for when you have a date that was
    created in the wrong timezone.  For example, since datetimepicker
    does not have timezone support, when a user clicks on
    2016/01/01 12:00, datetimepicker returns that time in the local
    time zone.  However, we might want 2016/01/01 12:00 pacific time.
    We can't just convert the date, because we don't want to convert
    12:00 ET to 9:00 PT, we want to shift 12:00 ET to 12:00 PT.
*/
export function shiftDateToTimezone(newDate, targetTimezone) {
    const formattedDate = moment(newDate).format('YYYY-MM-DD HH:mm');
    const dateInTargetTimezone = moment.tz(formattedDate, targetTimezone);

    return dateInTargetTimezone.toDate();
}

// For many of our dates we actually cut off on the day prior if the time is before 11pm
export function shiftMonthDayForThreshold(date, threshold = 23) {
    // if this default threshold value ever changes, be sure to update
    // the deadline_with_threshold method in cohort.rb

    const momentous = moment(date);

    // If the time is before 11pm in your timezone, show the previous day
    if (momentous.hour() < threshold) {
        momentous.subtract(1, 'day');
    }

    return momentous.toDate();
}

// timezoneDescriptor is generally null, but can be set to adminRefTimezone if you
// want to see Pacific Time in the editor
export function formattedUserFacingDateTime(date, timezoneDescriptor) {
    return _getTimeStringWithAbbr(date, 'lll', timezoneDescriptor);
}

export function formattedUserFacingMonthDay(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'M/D');
}

export function formattedUserFacingDay(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'D');
}

export function formattedUserFacingYear(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'YYYY');
}

export function formattedUserFacingMonthLong(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'MMMM');
}

export function formattedUserFacingYearLong(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'YYYY');
}

export function formattedUserFacingMonthDayLong(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'MMMM D');
}

export function formattedUserFacingMonthDayShort(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'MMM D');
}

export function formattedUserFacingMonthDayYearShort(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'M/D/YY');
}

export function formattedUserFacingMonthDayYearLong(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'MMMM D, YYYY');
}

export function formattedUserFacingMonthDayYearMedium(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'MMM D YYYY');
}

export function formattedUserFacingMonthYearLong(date, useDateThreshold = true) {
    const momentous = _getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'MMMM YYYY');
}

export function formattedUserFacingDayOfWeekMonthDay(date, useDateThreshold = true) {
    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
    return _amDateFormat(momentous, 'dddd, MMMM D');
}

export function formattedDateRange(startDate, endDate) {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    let range = startMoment.format('MMMM D');

    if (startMoment.format('M') === endMoment.format('M')) {
        // same month and same year
        range += `–${endMoment.format('D, YYYY')}`;
    } else if (startMoment.format('YY') === endMoment.format('YY')) {
        // different month, same year
        range += `–${endMoment.format('MMMM D, YYYY')}`;
    } else {
        // different month and year
        range += `, ${startMoment.format('YYYY')}–${endMoment.format('MMMM D, YYYY')}`;
    }

    return range;
}

function _getMomentUserFacingMonthDay(date, useDateThreshold = true) {
    if (!date) return '';

    if (useDateThreshold) return moment(shiftMonthDayForThreshold(date));

    return moment(date);
}

function _getTimeStringWithAbbr(date, format, timezoneDescriptor) {
    if (!date) {
        return '';
    }

    let inTimeZone;
    if (timezoneDescriptor === 'adminRefTimezone') {
        inTimeZone = _applyTimezone(date, ADMIN_REF_TIMEZONE);
    } else {
        inTimeZone = _applyTimezone(date, moment.tz.guess());
    }
    return _amDateFormat(inTimeZone, `${format} z`);
}

// adapted from angular-moment.js
function _isUndefinedOrNull(val) {
    return val === undefined || val === null;
}

// adapted from angular-moment.js #amDateFormat
function _amDateFormat(value, format) {
    if (_isUndefinedOrNull(value)) {
        return '';
    }

    const momentous = moment(value);
    if (!momentous.isValid()) {
        return '';
    }

    return momentous.format(format);
}

// adapted from angular-moment.js#amTimezone
function _applyTimezone(date, timezone) {
    const momentous = moment(date);

    if (momentous.tz) {
        return momentous.tz(timezone);
    }
    return momentous;
}

export const subtractMonths = (months, date = new Date()) => new Date(date).setMonth(date.getMonth() - months);

export const addMonths = (months, date = new Date()) => new Date(date).setMonth(date.getMonth() + months);

export const subtractDays = (days, date = new Date()) => {
    const updated = new Date(date);
    updated.setDate(updated.getDate() - days);
    return updated;
};

export const addDays = (days, date = new Date()) => {
    const updated = new Date(date);
    updated.setDate(updated.getDate() + days);
    return updated;
};
