import { useCallback, useMemo, useState } from 'react';
import { linearBlueToTransparentGradientStyle, linearWhiteGradientStyle } from 'ExecEdEligibility/helpers/gradients';
import {
    type ExecEdEligibilityBundle,
    type EligibleProgram,
    type ExecEdCertCategory,
} from 'ExecEdEligibility/ExecEdEligibility.types';
import { programCategoryForProgramType } from 'ExecEdEligibility/helpers/utils';
import { ExecEdCertCategoryConfigs } from 'ExecEdEligibility/helpers/ExecEdCategories';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import { type ProgramType, type ProgramTypeConfig } from 'Program';
import { useTranslation } from 'react-i18next';
import { type CurrentUserIguanaObject } from 'Users';
import { type navigationHelper } from 'navigationHelper';
import { CertRecommendedProgram } from './CertRecommendedProgram';
import { CertEligibleProgramCategory } from './CertEligibleProgramCategory';

type Props = {
    filteredEligibilityBundle: ExecEdEligibilityBundle;
    programTypeConfigs: { [programType: string]: ProgramTypeConfig };
    loadUrl: ReturnType<typeof navigationHelper>['loadUrl'];
    currentUser: CurrentUserIguanaObject;
};

export function CertModalContent({ filteredEligibilityBundle, programTypeConfigs, loadUrl, currentUser }: Props) {
    const { t } = useTranslation('back_royal');

    const recommendedProgramType = filteredEligibilityBundle?.recommendedProgramType;
    const recommendedProgramCategory = recommendedProgramType && programCategoryForProgramType(recommendedProgramType);
    const [loading, setLoading] = useState(false);

    const otherProgramsByCategory = useMemo(() => {
        const eligiblePrograms = filteredEligibilityBundle?.eligiblePrograms;
        const otherPrograms = eligiblePrograms?.filter(p => p.programType !== recommendedProgramType);
        return otherPrograms?.reduce((acc: { [category: string]: EligibleProgram[] }, program) => {
            const category = programCategoryForProgramType(program.programType);
            return category
                ? {
                      ...acc,
                      [category]: [...(acc[category] || []), program],
                  }
                : acc;
        }, {});
    }, [recommendedProgramType, filteredEligibilityBundle]);

    const orderedCategories = useMemo(
        () =>
            otherProgramsByCategory &&
            Object.keys(otherProgramsByCategory).sort(
                (a, b) =>
                    ExecEdCertCategoryConfigs[a as ExecEdCertCategory].displaySortOrder -
                    ExecEdCertCategoryConfigs[b as ExecEdCertCategory].displaySortOrder,
            ),
        [otherProgramsByCategory],
    );

    const handleReviewRegisterClick = useCallback(
        (programType: ProgramType) => {
            if (loading) return;
            setLoading(true);
            currentUser.registerPreApprovedExecEdProgram(programType).finally(() => setLoading(false));
        },
        [currentUser, loading, setLoading],
    );

    const handleLearnMoreClick = useCallback(
        (programType: ProgramType) => {
            loadUrl(programTypeConfigs[programType].marketingPageUrl, '_blank');
        },
        [loadUrl, programTypeConfigs],
    );

    if (loading) {
        return (
            <div className="tw-mt-5 tw-flex tw-w-full tw-items-center tw-justify-center">
                <FrontRoyalSpinner color="blue" className="no-delay static-height" />
            </div>
        );
    }

    return (
        <div className="cert-modal-content tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-[30px] md:tw-relative md:-tw-top-[40px] md:tw-flex-row md:tw-items-start md:tw-justify-center md:tw-gap-[34px]">
            {/* Recommended Program */}
            <div
                className="tw-flex tw-w-[256px] tw-shrink-0 tw-grow-0 tw-flex-col tw-items-center tw-border tw-border-t-0 tw-border-solid tw-border-white tw-pb-[25px] md:tw-sticky md:tw-top-[227px] md:tw-z-20 md:tw-mt-[2px]"
                style={{ ...linearBlueToTransparentGradientStyle('to top') }}
            >
                <div className="tw-relative -tw-top-[12px] tw-flex tw-w-full tw-items-center tw-justify-center">
                    <div className="line tw-relative -tw-left-[1px] tw-h-[1px] tw-flex-1 tw-bg-white" />
                    <div className="tw-max-w-[90%] tw-px-[9px] tw-uppercase">
                        {t('exec_ed_eligibility.cert_acceptance_modal.recommended')}
                    </div>
                    <div className="line tw-relative -tw-right-[1px] tw-h-[1px] tw-flex-1 tw-bg-white" />
                </div>
                {recommendedProgramType && (
                    <CertRecommendedProgram
                        category={recommendedProgramCategory as ExecEdCertCategory}
                        recommendedProgramType={recommendedProgramType}
                        onReviewRegisterClick={handleReviewRegisterClick}
                        onLearnMoreClick={handleLearnMoreClick}
                    />
                )}
            </div>

            {/* Other Available Programs */}
            <div className="tw-w-full tw-max-w-[560px]">
                <div className="tw-mb-[10px] tw-flex tw-h-[1.5em] tw-w-full tw-items-center tw-justify-center rtl:tw-flex-row-reverse md:tw-sticky md:tw-top-[214px] md:tw-z-20 md:-tw-mt-[10px]">
                    <div className="line tw-h-[1px] tw-flex-1" style={{ ...linearWhiteGradientStyle('to left') }} />
                    <div className="tw-relative tw-max-w-[90%] tw-whitespace-nowrap tw-px-[10px] tw-uppercase">
                        {t('exec_ed_eligibility.cert_acceptance_modal.also_available')}
                    </div>
                    <div className="line tw-h-[1px] tw-flex-1" style={{ ...linearWhiteGradientStyle('to right') }} />
                </div>
                {orderedCategories?.map(category => (
                    <CertEligibleProgramCategory
                        key={category}
                        category={category as ExecEdCertCategory}
                        eligibilityBundle={filteredEligibilityBundle!}
                        onReviewRegisterClick={handleReviewRegisterClick}
                        onLearnMoreClick={handleLearnMoreClick}
                    />
                ))}
            </div>
        </div>
    );
}
