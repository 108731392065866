import moment from 'moment-timezone';

const tzForOffsetting = 'America/New_York';

// If you add days across daylight savings time, you can end up with a new
// date that is not at the time you expect.  This method makes sure that
// you are always adding dates following the rules of the default timezone
export function addInDefaultTimeZone(date: Date, val: number, unit: moment.unitOfTime.DurationConstructor): Date;
export function addInDefaultTimeZone(
    date: moment.Moment,
    val: number,
    unit: moment.unitOfTime.DurationConstructor,
): moment.Moment;
export function addInDefaultTimeZone(
    date: Date | moment.Moment,
    val: number,
    unit: moment.unitOfTime.DurationConstructor,
) {
    // we don't want to unexpectedly change the time zone on a moment
    // object that gets passed in, so unlike moment.add(), this method
    // always returns a clone rather than updating the original object
    const m = moment(date).clone();
    m.tz(tzForOffsetting).add(val, unit);

    // if you pass in moment, you get moment back.  If you pass in a
    // date, you get a date back.
    return '_isAMomentObject' in date ? m : m.toDate();
}
