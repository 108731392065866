import moment from 'moment-timezone';

export default angular
    .module('DateHelper', [])
    .factory('dateHelper', [
        '$injector',
        $injector => {
            const amTimezone = $injector.get('$filter')('amTimezone');
            const amDateFormat = $injector.get('$filter')('amDateFormat');
            return {
                ADMIN_REF_TIMEZONE: 'America/Los_Angeles',
                SCREENSHOT_SPEC_REF_TIMEZONE: 'America/New_York',
                tzForOffsetting: 'America/New_York',

                // Timezone-aware comparison method to check if two dates fall across different calendar days in the specified timezone
                onDifferentDays(date1, date2, timezone) {
                    const moment1 = moment(date1).tz(timezone);
                    const moment2 = moment(date2).tz(timezone);

                    return !moment1.isSame(moment2, 'day');
                },

                /*
                This method is useful for when you have a date that was
                created in the wrong timezone.  For example, since datetimepicker
                does not have timezone support, when a user clicks on
                2016/01/01 12:00, datetimepicker returns that time in the local
                time zone.  However, we might want 2016/01/01 12:00 pacific time.
                We can't just convert the date, because we don't want to convert
                12:00 ET to 9:00 PT, we want to shift 12:00 ET to 12:00 PT.
            */
                shiftDateToTimezone(newDate, targetTimezone) {
                    const formattedDate = moment(newDate).format('YYYY-MM-DD HH:mm');
                    const dateInTargetTimezone = moment.tz(formattedDate, targetTimezone);

                    return dateInTargetTimezone.toDate();
                },

                // For many of our dates we actually cut off on the day prior if the time is before 11pm.
                // This is commonly done for deadline dates (e.g. application deadlines and registration
                // deadlines); however, you probably don't want to be using the date threshold when
                // communicating start dates (e.g. cohort start dates).
                shiftMonthDayForThreshold(date, threshold) {
                    if (threshold === undefined) {
                        // if this default threshold value ever changes, be sure to update
                        // the deadline_with_threshold method in cohort.rb
                        threshold = 23;
                    }

                    const momentous = moment(date);

                    // If the time is before 11pm in your timezone, show the previous day
                    if (momentous.hour() < threshold) {
                        momentous.subtract(1, 'day');
                    }

                    return momentous.toDate();
                },

                // If you add days across daylight savings time, you can end up with a new
                // date that is not at the time you expect.  This method makes sure that
                // you are always adding dates following the rules of the default timezone
                addInDefaultTimeZone(date, val, unit) {
                    // we don't want to unexpectedly change the time zone on a moment
                    // object that gets passed in, so unlike moment.add(), this method
                    // always returns a clone rather than updating the original object
                    const m = moment(date).clone();
                    m.tz(this.tzForOffsetting).add(val, unit);

                    // if you pass in moment, you get moment back.  If you pass in a
                    // date, you get a date back.
                    return date._isAMomentObject ? m : m.toDate();
                },

                // timezoneDescriptor is generally null, but can be set to adminRefTimezone if you
                // want to see Pacific Time in the editor
                formattedUserFacingDateTime(date, timezoneDescriptor) {
                    return this._getTimeStringWithAbbr(date, 'lll', timezoneDescriptor);
                },

                formattedUserFacingDayDateTime(date, format, timezone) {
                    return moment(date).tz(timezone).format(format);
                },

                formattedUserFacingRelativeDayDate(date, format, timezone) {
                    if (moment(date).isBetween(moment().startOf('day'), moment().endOf('day'))) {
                        return 'today';
                    }
                    if (
                        moment(date).isBetween(
                            moment().add(1, 'day').startOf('day'),
                            moment().add(1, 'day').endOf('day'),
                        )
                    ) {
                        return 'tomorrow';
                    }
                    return moment(date).tz(timezone).format(format);
                },

                formattedUserFacingMonthDay(date, useDateThreshold) {
                    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
                    return amDateFormat(momentous, 'M/D');
                },

                formattedUserFacingDay(date, useDateThreshold) {
                    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
                    return amDateFormat(momentous, 'D');
                },

                formattedUserFacingMonthLong(date, useDateThreshold) {
                    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
                    return amDateFormat(momentous, 'MMMM');
                },

                formattedUserFacingMonthDayLong(date, useDateThreshold) {
                    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
                    return amDateFormat(momentous, momentous._locale?._abbr === 'zh' ? 'M月D日' : 'MMMM D');
                },

                formattedUserFacingMonthDayShort(date, useDateThreshold) {
                    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
                    return amDateFormat(momentous, 'MMM D');
                },

                formattedUserFacingMonthDayYearShort(date, useDateThreshold) {
                    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
                    return amDateFormat(momentous, 'M/D/YY');
                },

                formattedUserFacingMonthDayYearLong(date, useDateThreshold) {
                    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
                    return amDateFormat(momentous, 'MMMM D, YYYY');
                },

                formattedUserFacingMonthYearLong(date, useDateThreshold) {
                    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
                    return amDateFormat(momentous, 'MMMM YYYY');
                },

                formattedUserFacingDayOfWeekMonthDay(date, useDateThreshold) {
                    const momentous = this._getMomentUserFacingMonthDay(date, useDateThreshold);
                    return amDateFormat(momentous, 'dddd, MMMM D');
                },

                formattedDateRange(startDate, endDate) {
                    const startMoment = moment(startDate);
                    const endMoment = moment(endDate);
                    let range = startMoment.format('MMMM D');

                    if (startMoment.format('M') === endMoment.format('M')) {
                        // same month and same year
                        range += `–${endMoment.format('D, YYYY')}`;
                    } else if (startMoment.format('YY') === endMoment.format('YY')) {
                        // different month, same year
                        range += `–${endMoment.format('MMMM D, YYYY')}`;
                    } else {
                        // different month and year
                        range += `, ${startMoment.format('YYYY')}–${endMoment.format('MMMM D, YYYY')}`;
                    }

                    return range;
                },

                _getMomentUserFacingMonthDay(date, useDateThreshold) {
                    if (!date) {
                        return '';
                    }
                    if (useDateThreshold !== false) {
                        useDateThreshold = true;
                    }

                    if (useDateThreshold) {
                        return moment(this.shiftMonthDayForThreshold(date));
                    }
                    return moment(date);
                },

                _getTimeStringWithAbbr(date, format, timezoneDescriptor) {
                    if (!date) {
                        return '';
                    }

                    let timeZone;
                    if (timezoneDescriptor === 'adminRefTimezone') {
                        timeZone = amTimezone(date, this.ADMIN_REF_TIMEZONE);
                    } else {
                        timeZone = amTimezone(date, moment.tz.guess());
                    }
                    return amDateFormat(timeZone, `${format} z`);
                },
            };
        },
    ])
    .filter('formattedUserFacingDateTime', [
        'dateHelper',
        dateHelper => date => dateHelper.formattedUserFacingDateTime(date),
    ])
    .filter('formattedUserFacingMonthDayYearShort', [
        'dateHelper',
        dateHelper => date => dateHelper.formattedUserFacingMonthDayYearShort(date),
    ]);
