import { bool, insertAt, camelize, snakeCase, underscore, padNumber } from 'String';

// FIXME: just remove this file and use the functional approach

String.prototype.bool = function () {
    return bool(this);
};

String.prototype.insertAt = function (index, string) {
    return insertAt(this, index, string);
};

String.prototype.camelize = function (capitalize) {
    return camelize(this, capitalize);
};

String.prototype.snakeCase = function (separator) {
    return snakeCase(this, separator);
};

String.prototype.underscore = function () {
    return underscore(this);
};

String.padNumber = (n, width, z = '0') => {
    return padNumber(n, width, z);
};
