import angularModule from 'DragAndDrop/angularModule/scripts/lib/drag_and_drop';

angularModule.directive('droppable', [
    'DroppableDirHelper',
    DroppableDirHelper => ({
        link(scope, elem, attrs) {
            new DroppableDirHelper(elem, scope, attrs);
        },
    }),
]);
