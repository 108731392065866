import { type ProgramType } from 'Program';

export enum ExecEdCertCategory {
    ai_and_future_of_business = 'ai_and_future_of_business',
    data_and_finance = 'data_and_finance',
    rise_to_c_suite = 'rise_to_c_suite',
}

export type EligibleProgram = {
    programType: ProgramType;
    promotedCohortIdWhenActivated: string;
    scholarshipLevel: number;
};

export enum ProgramPriorityWeights {
    cmo_cert = 1,
    cto_cert = 3,
    executive_cert = 4,
    founders_cert = 5,
    data_science_foundations_cert = 6,
    ai_for_technical_leaders_cert = 7,
    bus_analytics_leaders_cert = 8,
    fin_for_non_fin_managers_cert = 9,
    tyb_ai_cert = 10,
    tyb_blockchain_cert = 11,
    prototype_gpt_cert = 12,
}

export type ExecEdEligibilityBundle = {
    createdAt: string;
    updatedAt: string;
    userId: string;
    id: string;
    recommendedProgramType: ProgramType;
    eligiblePrograms: EligibleProgram[];
    activatedAt: number | null;
};

export type ExecEdCertCategoryConfig = {
    programTypes: ProgramType[];
    modalHeadingLocale: { key: string; values?: Record<string, string | number> };
    displaySortOrder: number;
};
