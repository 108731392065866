/* eslint-disable arrow-body-style */
import * as Sentry from '@sentry/browser';
import Auid from 'Auid';
import BuildConfig from 'BuildConfig';
import NetworkConnection from 'NetworkConnection';
import moment from 'moment';
import ClientStorage from 'ClientStorage';
import { tracesSampler } from '../..';

// adapted from http://www.bennadel.com/blog/2542-Logging-Client-Side-Errors-With-AngularJS-And-Stacktrace-js.htm
export default angular.module('FrontRoyal.ErrorLogService', ['Injector']).factory('ErrorLogService', [
    '$injector',

    $injector => {
        const $log = $injector.get('$log');
        const $window = $injector.get('$window');

        let configureSentryPromise;

        const errorLogServiceErrorCounts = {};
        const sentryErrorCounts = {};

        const MAX_ERRORS_PER_KEY = 10;

        const PATTERNS_TO_IGNORE = [
            /Lexer Error/, // mathjax?
            '$digest() iterations reached', // one day we should maybe look into some of these, but for now we're just ignoring them anyway
            'Front Royal API Handler actually handled this one', // We set
            'There was an issue charging the provided card', // possibly unhandled exception when handling the 402 from subscriptions_controller
            'Cannot create viewModel for component that is unrelated to this frame', // We've been ignoring this for years and the editor seems to be working
            'digest already in progress', // this COULD be an issue where we need to use safeApply, but there are so many false positives, it's not worth it
            'null already in progress', // see note next to digest already in progress
            /Non-Error promise rejection captured with value: Object Not Found Matching Id/, // see also: https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/37
            'Please choose a city.', // we throw an error in order to clear out the input field in attachAMapAutoCompleteToInput.js, but it doesn't need to bubble up to Sentry
        ];

        const REQUEST_URLS_TO_IGNORE = [
            // Facebook flakiness
            /graph\.facebook\.com/i,
            // Facebook blocked
            /connect\.facebook\.net\/en_US\/all\.js/i,
            // Woopra flakiness
            /eatdifferent\.com\.woopra-ns\.com/i,
            /static\.woopra\.com\/js\/woopra\.js/i,
            // Chrome extensions
            /extensions\//i,
            /^chrome:\/\//i,
            // Other plugins
            /webappstoolbarba\.texthelp\.com\//i,
            /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
            /coursetalk-reviews.js/,
        ];

        // If the pattern is a string then see if the value includes it.
        // Otherwise use match, which converts the pattern to a RegExp.
        const includesOrMatches = (value, pattern) =>
            !!((value && typeof pattern === 'string' && value.includes(pattern)) || value?.match(pattern));

        // track how many of a given error we've seen (in both the error log service context and the Sentry context)
        // error log service will always delegate to Sentry, but Sentry sometimes gets uncaught errors that the error
        // log service does not receive. So, by tracking in both, we ensure that no errors sneak by and get logged
        // hundreds of times to either GetSentry or our own event endpoint
        function incrementAndTestErrorCount(errorCounts, key = 'BLANK') {
            if (angular.isDefined(errorCounts[key])) {
                errorCounts[key] += 1;
            } else {
                errorCounts[key] = 1;
            }

            if (errorCounts[key] === MAX_ERRORS_PER_KEY + 1) {
                log.notify(
                    `Error already logged ${MAX_ERRORS_PER_KEY} times, further errors suppressed: ${key}`,
                    'Limit Reached',
                    {
                        fingerprint: ['errorsSuppressed'],
                    },
                );
            }

            if (errorCounts[key] > MAX_ERRORS_PER_KEY) {
                return false;
            }

            return true;
        }

        // Configure Sentry.
        // Returns a promise which will be resolved once
        // Sentry is configured.
        function configureSentry() {
            return $injector.get('$q')(resolve => {
                const ConfigFactory = $injector.get('ConfigFactory');
                ConfigFactory.getConfig().then(frontRoyalConfig => {
                    // determine environment for log segmentation
                    let sentryEnvironment = 'Local Development';
                    if (frontRoyalConfig.app_env_name) {
                        if (frontRoyalConfig.app_env_name.match(/staging/i)) {
                            sentryEnvironment = 'Staging';
                        } else if (frontRoyalConfig.app_env_name.match(/production/i)) {
                            sentryEnvironment = 'Production';
                        }
                    }

                    // See setup steps at https://docs.sentry.io/platforms/javascript/performance/?original_referrer=https%3A%2F%2Fwww.google.com%2F
                    // We're doing this instead of adding Sentry.BrowserTracing to the integrations object
                    Sentry.addTracingExtensions();

                    Sentry.init({
                        dsn: frontRoyalConfig.sentry_dsn,
                        transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
                        transportOptions: {},
                        release: BuildConfig.build_number,
                        maxMessageLength: 1024, // up the maximum message length for better error message information
                        environment: `${sentryEnvironment} - Client`,

                        beforeSend: (sentryEvent, hint) => {
                            // determine exception message
                            const key = hint.originalException && hint.originalException.message;

                            // filter out any known, ignorable request URLs
                            if (REQUEST_URLS_TO_IGNORE.some(url => includesOrMatches(sentryEvent.request.url, url))) {
                                return null;
                            }

                            // filter out any known, ignorable patterns
                            if (PATTERNS_TO_IGNORE.some(pattern => includesOrMatches(key, pattern))) {
                                return null;
                            }

                            // filter out any overly-redundant logging
                            if (!incrementAndTestErrorCount(sentryErrorCounts, key)) {
                                return null;
                            }

                            fingerprintKnownButUncontrolledErrors(sentryEvent);
                            mutateUnhandledRejectionError(sentryEvent);

                            return sentryEvent;
                        },
                        tracesSampler: samplingContext => tracesSampler(samplingContext, frontRoyalConfig),
                    });

                    resolve(Sentry);
                });
            });
        }

        function fingerprintKnownButUncontrolledErrors(sentryEvent) {
            const errorText = sentryEvent.exception && sentryEvent.exception.values[0].value;
            if (!errorText) {
                return;
            }

            if (errorText.match(/\[\$rootScope:inprog\]/)) {
                sentryEvent.fingerprint = '[$rootScope:inprog]';
            }
        }

        function mutateUnhandledRejectionError(sentryEvent) {
            const originalSentryEvent = _.clone(sentryEvent);

            try {
                sentryEvent.extra = sentryEvent.extra || {};
                const sentryEventEntry = sentryEvent.exception.values[0];
                const errorText = sentryEventEntry.value;
                const stack = JSON.stringify(sentryEventEntry.stacktrace);
                const regex = /Possibly unhandled rejection:\s+/;
                let errorMessagePrefix = 'Possibly unhandled rejection';
                let errMessage;

                // Sometimes this shows up in the stack but is truncated out of the error message
                const handledMatch =
                    errorText.match(/handledByFrontRoyalApiErrorHandler/) ||
                    stack.match(/handledByFrontRoyalApiErrorHandler/);

                const statusMatch = errorText.match(/"status":\s*(\d+)/);
                const status = statusMatch && statusMatch[1];

                // the optional double-backslash after url and after the colon show up when we
                // are matching the url in the stack
                const urlRegex = /url\\?":\s?\\?"([^"]*)/;

                // Sometimes the url is truncated out of the errorText, but we can find it in
                // the stack (maybe only on an http retry, in which case the url is in the
                // config, which is included in the stack?)
                const urlMatch = errorText.match(urlRegex) || stack.match(urlRegex);
                const url = urlMatch && urlMatch[1];

                const apiRequest = url?.match(/\/api/);

                if (handledMatch) {
                    // We want to be able to ignore anything that was already handled by
                    // the front royal api error handler
                    sentryEvent.fingerprint = ['handledByFrontRoyalApiErrorHandler'];
                    errorMessagePrefix = 'Front Royal API Handler actually handled this one';
                } else if (status && !apiRequest) {
                    // Trying to get a better error message out of errors like
                    // https://sentry.io/organizations/pedago/issues/1670780853/?environment=Production+-+Client&project=1491374&query=is%3Aunresolved
                    if (url) {
                        errMessage = `Received status code ${status} on a non-API request`;
                        sentryEvent.extra.url = url;
                    } else {
                        errMessage = `Received status code ${status} on a request for which we could not determine the url`;
                        sentryEvent.extra.url = 'Unknown, probably due to response being truncated';
                    }
                } else if (status && url) {
                    // I do not really expect to get here.  We should be handling errors in api requests
                    errMessage = `Received unhandled error exception with status code ${status} on api request.`;
                    sentryEvent.extra.url = url;
                } else if (errorText && errorText.match(regex)) {
                    const jsonResponse = errorText.replace(regex, '');
                    const responseData = JSON.parse(jsonResponse);

                    // Sometimes the data is at the top level, sometimes there is an
                    // error array
                    [responseData].concat(responseData.errors || []).forEach(err => {
                        // Since the html responses are so long, remove them.  (config is
                        // long too, but there might be some interesting stuff in there and
                        // we're not sure exactly what to remove.  Might revisit.)
                        if (err.data && typeof err.data === 'string' && err.data.match('<html')) {
                            err.data = 'HTML response REMOVED';
                        }

                        if (err.config) {
                            sentryEvent.extra.failedApiUrl = err.config.url;
                        }
                    });

                    errMessage = `${errorMessagePrefix}: ${JSON.stringify(responseData)}`;
                }
                if (errMessage) {
                    sentryEventEntry.value = errMessage;
                }
            } catch (err) {
                // If we encounter an error just set data back to the original
                sentryEvent = originalSentryEvent;
                // eslint-disable-next-line no-console
                console.error(err);
            }
        }

        // Configure Sentry if it is not already configured.
        // Returns a promise which will be resolved once
        // Sentry is configured.
        function ensureSentryConfigured() {
            if (!Sentry) {
                throw new Error('Sentry not available.');
            }

            if (!configureSentryPromise) {
                configureSentryPromise = $injector.get('$q')(resolve => {
                    // eslint-disable-next-line no-shadow
                    configureSentry().then(Sentry => {
                        resolve(Sentry);
                    });
                });
            }
            return configureSentryPromise;
        }

        // Call log.notify, which will log to both
        // ErrorLogService and Sentry.  If Sentry is not
        // setup (which will be the case in development mode)
        // then throw the error.
        function log(exception, cause) {
            // I do not know how this happens
            if (!exception) {
                exception = new Error('Unknown error.');
            }

            // ignore stupid blur error triggered by manual blurring
            // in challenge_blank_dir
            if (
                exception.message &&
                exception.stack &&
                exception.message.match('apply already in progress') &&
                exception.stack.match(/focus.trigger/)
            ) {
                // eslint-disable-next-line no-console
                console.error('Ignoring apply already in progress triggered by blur');
                return;
            }

            const message = exception.toString();
            if (message.match(/Possibly unhandled rejection/)) {
                // suppress "possibly unhandled rejection" messages on FrontRoyalApiError handling
                // of 401 or 406 responses, which we believe to have handled already.
                // Also suppress 409 responses as that indicates a conflict that we should be handling
                // explicitly where we are doing the call (ex. team position and interest updating).
                if (message.match(/"status":(401|406|409|-1|0)/)) {
                    return;
                }

                // ignore exceptions on logout
                if (message.match(/User was not found or was not logged in/) && message.match(/"status":(404)/)) {
                    return;
                }

                // ignore exceptions on invalid card (which is displayed in a special modal)
                if (message.match(/card was declined|insufficient funds/) && message.match(/"status":(402)/)) {
                    return;
                }

                if (message.match(/Session expired/)) {
                    return;
                }

                if (message.match(/BlockedByFrontRoyalStore/)) {
                    return;
                }
            }

            if (!log.notify(exception, cause)) {
                $log.error(...[exception, cause]);
            }
        }

        function tryToSetUser() {
            const $rootScope = $injector.get('$rootScope');

            if ($rootScope.currentUser) {
                Sentry.setUser({
                    email: $rootScope.currentUser.email,
                    id: $rootScope.currentUser.id,
                });
            } else {
                Sentry.setUser({
                    id: Auid.get($rootScope.currentUser),
                });
            }
        }

        log.notifyInProd = (exception, cause, extra) => {
            // If config is not yet available, we assume you are not in dev mode and silence
            // the error the way we would on production. Since that can only really happen on
            // cordova anyway, it's hard to imagine this mattering in practice.
            const config = $injector.get('ConfigFactory').getSync(true);
            const treatAsDev = config?.appEnvType() === 'development' || $window.RUNNING_IN_TEST_MODE;

            if (treatAsDev) {
                const err = typeof exception === 'string' ? new Error(exception) : exception;
                if (extra) {
                    // eslint-disable-next-line no-console
                    console.error('EXTRA ', JSON.stringify(extra));
                }
                throw err;
            }
            return log.notify(exception, cause, extra);
        };

        // Log the error to ErrorLogService and, if
        // it is available, Sentry.
        //
        // returns a promise which will be resolved if Sentry exists
        // and will be rejected if not
        log.notify = (exception, cause, extra) => {
            extra = extra || exception.extra || {};
            extra.clientTimestamp = `${moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS')} UTC`;
            extra.offline = NetworkConnection.offline;
            extra.logged_in_as = ClientStorage.getItem('logged_in_as');

            if (typeof exception === 'string') {
                exception = new Error(exception);
            }

            const message = ((exception && exception.message) || '').slice(0, 1024);

            let fingerprint;
            if (extra.fingerprint) {
                fingerprint = extra.fingerprint;
                delete extra.fingerprint;
            }
            if (!fingerprint) {
                // I think that in cases where we end up going through notify,
                // we prefer to have sentry aggregate all errors with the same message.
                // Uncaught errors do not go through here.
                fingerprint = exception && message ? [message] : undefined;
            }

            const level = extra.level || 'error';
            delete extra.level;

            // we used to pass all the remaining `extra` values in an `error_debug` property, but kept hitting
            // segment character limits which resulted in HTTP 400 failures, so we're axing ones that seems unused.
            // The keys included here are ones passed from FrontRoyalErrorBoundary
            const eventPayload = {
                message,
                label: extra.label,
                server_error: extra.server_error,
            };

            // bail out early before logging to event logger or Sentry if we've seen this error too many times
            if (!incrementAndTestErrorCount(errorLogServiceErrorCounts, message)) {
                // return true because we don't want to throw the error in log() above
                return true;
            }

            angular.extend(eventPayload, {
                label: (message || '').slice(0, 256),
                message: (message || '').slice(0, 1024),
            });

            // not sure why injecting EventLogger directly does not
            // work in this case, but it caused trouble.
            const EventLogger = $injector.get('EventLogger');
            EventLogger.log('error', eventPayload);

            // I tried to push the Sentry check all the way
            // down into configureSentry(), and reject the returned promise
            // if it was unavailable, but this didn't work because
            // havig the log() function throw the error in the callback
            // led to an infinite loop.  It has to be synchronous.
            const config = $injector.get('ConfigFactory').getSync(true);
            if (config && config.sentry_dsn) {
                log.logToSentry({ level, fingerprint, cause, extra, exception });

                return true;
            }
            return false;
        };
        log.ensureSentryConfigured = ensureSentryConfigured;

        log.logToSentry = ({ level, fingerprint, cause, extra, exception }) => {
            // if we have valid Sentry config, attempt to log
            return (
                ensureSentryConfigured()
                    // eslint-disable-next-line no-shadow
                    .then(Sentry => {
                        // Scope tags and other misc context per event
                        return Sentry.withScope(scope => {
                            tryToSetUser();
                            scope.setTag('build_number', BuildConfig.build_number);
                            scope.setTag('build_timestamp', BuildConfig.build_timestamp);
                            scope.setTag('commit', BuildConfig.build_commit);
                            scope.setTag('full_url', document.URL);
                            scope.setLevel(level);
                            scope.setFingerprint(fingerprint);

                            const extraInfo = _.extend(
                                {
                                    cause,
                                },
                                extra,
                            );
                            // eslint-disable-next-line no-restricted-syntax
                            for (const [key, value] of Object.entries(extraInfo)) {
                                scope.setExtra(key, value);
                            }

                            // Send to Sentry
                            return Sentry.captureException(exception);
                        });
                    })
                    .catch(err => {
                        // We never expect to get here. captureException is a fire-and-forget
                        // call, so even if the request fails, that wouldn't result
                        // in an error here.  However, if we have some error in the code that
                        // generates the message for sentry, we would want to know about it.
                        // We check the message first to prevent an infinite loop where we keep
                        // trying to log to sentry about how we can't log to sentry
                        const msg = 'Failed to log an event to Sentry';
                        if (exception.message && !exception.message.match(msg)) {
                            log.notify('Failed to log an event to Sentry', err, {
                                originalException: {
                                    level,
                                    fingerprint,
                                    cause,
                                    extra,
                                    exception,
                                },
                            });
                        }
                    })
            );
        };

        // Return the logging function.
        return log;
    },
]);
