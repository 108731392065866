import { ProgramType } from 'Program';
import { ExecEdCertCategory, type ExecEdCertCategoryConfig } from '../ExecEdEligibility.types';

export const ExecEdCertCategoryConfigs: Record<ExecEdCertCategory, ExecEdCertCategoryConfig> = {
    [ExecEdCertCategory.ai_and_future_of_business]: {
        programTypes: [
            ProgramType.tyb_ai_cert,
            ProgramType.tyb_blockchain_cert,
            ProgramType.prototype_gpt_cert,
            ProgramType.ai_for_technical_leaders_cert,
        ],
        modalHeadingLocale: { key: 'exec_ed_eligibility.cert_acceptance_modal.heading_ai_and_future_of_business' },
        displaySortOrder: 3,
    },
    [ExecEdCertCategory.data_and_finance]: {
        programTypes: [
            ProgramType.fin_for_non_fin_managers_cert,
            ProgramType.bus_analytics_leaders_cert,
            ProgramType.data_science_foundations_cert,
        ],
        modalHeadingLocale: { key: 'exec_ed_eligibility.cert_acceptance_modal.heading_data_and_finance' },
        displaySortOrder: 2,
    },
    [ExecEdCertCategory.rise_to_c_suite]: {
        programTypes: [
            ProgramType.cto_cert,
            ProgramType.founders_cert,
            ProgramType.executive_cert,
            ProgramType.cmo_cert,
        ],
        modalHeadingLocale: { key: 'exec_ed_eligibility.cert_acceptance_modal.heading_rise_to_c_suite' },
        displaySortOrder: 1,
    },
};
