import { Transition } from '@headlessui/react';
import { type AnyObject } from '@Types';
import clsx from 'clsx';
import { Icon } from 'FrontRoyalIcon';
import { type ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { Typography } from 'Typography';

type Dismissable = {
    disableDismiss?: false;
    onDismiss: () => void;
};

type NonDismissable = {
    disableDismiss: true;
    onDismiss?: () => void;
};

type MaybeDismissable = Dismissable | NonDismissable;

type Props = {
    show: boolean;
    onAfterLeave?: () => void;
    children: ReactNode;
    cta?: {
        text: { headline: { key: string; params?: AnyObject }; subtext?: { key: string; params?: AnyObject } };
        buttonText: { key: string; params?: AnyObject };
        onClick: () => void;
    };
    className?: string;
} & MaybeDismissable;

export function DismissableMessage({ onDismiss, disableDismiss, show, onAfterLeave, children, cta, className }: Props) {
    const { t } = useTranslation('back_royal');

    return (
        <Transition
            show={show}
            appear
            enter="tw-transition tw-duration-300 tw-delay-500 tw-ease-out"
            enterFrom="tw-opacity-0 tw-translate-y-2"
            enterTo="tw-opacity-100 tw-translate-y-0"
            leave="tw-transition tw-duration-150 tw-ease-in"
            leaveFrom="tw-opacity-100"
            leaveTo="tw-opacity-0"
            afterLeave={onAfterLeave}
        >
            <div
                data-testid="dismissable-message"
                className={clsx(
                    'tw-relative tw-flex tw-max-h-full tw-w-full tw-max-w-[396px] tw-flex-col tw-rounded-md tw-border tw-border-white/40 tw-bg-map-blue-dark/90  tw-text-white',
                    className,
                )}
            >
                <div className="tw-px-3.5 tw-py-8 md:tw-px-8">
                    {!disableDismiss && (
                        <button
                            data-testid="dismiss-message-button"
                            type="button"
                            className="tw-absolute tw-right-0 tw-top-0 tw-rounded-md tw-p-2"
                            onClick={onDismiss}
                        >
                            <span className="tw-sr-only">{t('dismissable_message.close_message')}</span>
                            <Icon iconName="Close24" aria-hidden="true" className="tw-text-white/50" />
                        </button>
                    )}
                    <div className="tw-max-h-full tw-overflow-y-auto">{children}</div>
                </div>
                {!!cta && (
                    <div className="tw-rounded-b-md tw-bg-map-blue-darker tw-px-3.5 tw-py-5 md:tw-px-8">
                        <div className="tw-flex tw-h-fit tw-max-h-full tw-items-center tw-justify-between">
                            <div className="">
                                <Typography variant="body4" className="tw-text-xxs tw-uppercase tw-leading-[15px]">
                                    {t(cta.text.headline.key, cta.text.headline.params ?? {})}
                                </Typography>
                                {cta.text.subtext && (
                                    <Typography
                                        variant="body4"
                                        className="tw-text-sm tw-font-semibold tw-leading-[15px]"
                                    >
                                        {t(cta.text.subtext.key, cta.text.subtext.params ?? {})}
                                    </Typography>
                                )}
                            </div>
                            <div className="">
                                <button
                                    className="tw-min-w-[100px] tw-rounded-[20px] tw-bg-white tw-px-[7px] tw-py-[11.5px] tw-text-base tw-leading-none tw-text-[#58606D]"
                                    type="button"
                                    onClick={() => cta.onClick()}
                                >
                                    {t(cta.buttonText.key, cta.buttonText.params ?? {})}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Transition>
    );
}
