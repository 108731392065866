export default angular.module('DeepExtend', []).factory('DeepExtend', [
    () => {
        function extend(target, src) {
            angular.forEach(src, (srcValue, key) => {
                const existsOnTarget = target.hasOwnProperty(key);
                const targetValue = target[key];
                const srcType = _.isArray(srcValue) ? 'array' : typeof srcValue;
                const targetType = _.isArray(targetValue) ? 'array' : typeof targetValue;

                if (srcType !== 'object' || !existsOnTarget) {
                    // if it's not an object
                    target[key] = srcValue;
                } else if (existsOnTarget && targetType !== 'object') {
                    // if the key exists on the target, but it's not an object, override it
                    target[key] = srcValue;
                } else if (existsOnTarget && targetType === 'object') {
                    // if the key exists on the target, and it is an object, merge them
                    extend(targetValue, srcValue);
                } else {
                    throw new Error("How'd we get here?");
                }
            });

            return target;
        }

        return {
            extend,
        };
    },
]);
