export const linearBlueGradientStyle = (mainDirection: string) => ({
    backgroundImage: `linear-gradient(${mainDirection},rgba(34,102,208,1.0) 20%,rgba(12,44,99,1.0)), linear-gradient(to right,rgba(120,198,241,0.15),rgba(120,198,241,1.0))`,
});

export const linearBlueToTransparentGradientStyle = (direction: string) => ({
    backgroundImage: `linear-gradient(${direction},rgba(27,79,164,1.0),rgba(29,83,168,0.0))`,
});

export const linearLightBlueGradientStyle = (direction: string) => ({
    backgroundImage: `linear-gradient(${direction},rgba(51,109,192,1.0),rgba(25,70,149,1.0))`,
});

export const linearWhiteGradientStyle = (direction: string) => ({
    backgroundImage: `linear-gradient(${direction},rgba(255,255,255,1.0),rgba(255,255,255,0.0))`,
});

export const linearMaskImageGradient = (direction: string) => ({
    WebkitMaskImage: `linear-gradient(${direction},transparent,black 8%)`,
});
