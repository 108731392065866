import { useTranslation } from 'react-i18next';
import { QuanticShieldGold } from './QuanticShieldGold';

type Props = {
    name: string;
};

export function CertModalHeader({ name }: Props) {
    const { t } = useTranslation('back_royal');
    return (
        <>
            <h3 className="tw-mb-4 tw-flex tw-flex-col tw-items-center tw-text-center tw-leading-4 ">
                <span className="tw-text-xs tw-font-normal tw-uppercase tw-opacity-60">
                    {t('exec_ed_eligibility.cert_acceptance_modal.congratulations')}
                </span>
                <span className="tw-text-xs tw-font-medium tw-uppercase">{name}</span>
            </h3>
            <div className="logo tw-mb-5 tw-h-[55px] tw-w-[58px]">
                <QuanticShieldGold viewBox="95 185 115 110" className="tw-h-full tw-w-full" />
            </div>
            <p className=" tw-mx-auto tw-my-0 tw-text-center tw-leading-5 tw-opacity-60 max-xs:tw-max-w-[300px] xs:tw-w-auto md:tw-whitespace-nowrap">
                {t('exec_ed_eligibility.cert_acceptance_modal.you_are_eligible_to_enroll')}
            </p>
        </>
    );
}
