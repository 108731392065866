import angularModule from 'DragAndDrop/angularModule/scripts/lib/drag_and_drop';

angularModule.factory('DragAndDrop.EventListener', [
    'AClassAbove',
    '$document',
    'DragAndDrop.DragInfo',
    'safeApply',
    (AClassAbove, $document, DragInfo, safeApply) => {
        /*
            observe changes to an attribute that defines a drag/drop callback (i.e. dragstart, dragend, etc.)
            any time the attribute changes, create an EventListener that uses the callback to respond
            to some event.

            These listeners can be cleaned up later with EventListener.$stopObserving.
        */
        function observe(dragInfo, element, scope, attrs) {
            attrs._dragAndDropListeners = attrs._dragAndDropListeners || {};
            attrs.$observe(this.prototype.callbackType, expr => {
                if (!expr) {
                    return;
                }
                if (attrs._dragAndDropListeners[this.prototype.callbackType]) {
                    attrs._dragAndDropListeners[this.prototype.callbackType].destroy();
                }
                attrs._dragAndDropListeners[this.prototype.callbackType] = new this(dragInfo, element, scope, expr);
            });
        }

        /*
            A class that listens for dispatch of drag/drop events (i.e. dragstart, dragend, etc.) and
            helps connect those events to the angular scope ecosystem.  See comments below
            for the initialze method for more information
        */
        const EventListener = AClassAbove.subclass(function () {
            this.extend({
                $observe: observe,
                $stopObserving(attrs) {
                    angular.forEach(attrs._dragAndDropListeners, listener => {
                        listener.destroy();
                    });
                },
            });

            return {
                // The following arguments can be passed in in any order:
                // - dragInfo (instance of DragAndDrop.DragInfo)
                // - scope (an angular scope)
                // - expr (a string that will be evaluated with scope.$eval)
                // - callback (a function)
                //
                // element
                //  a raw html element or a jQuery wrapped element.  required.
                //
                // dragInfo
                //  can be null or an instance of DragInfo
                //  used to share information between all of the events that
                //  go along with a single drag of an element (which may or may
                //  not include a drop on some other element).  In most cases,
                //  it is only set on drag events (dragstart, dragenter, etc.). Drop events
                //  (drop, dragover, etc.), on the other hand, will use the dataTransfer
                //  object in the event to get a reference to the dragInfo object from
                //  the dragstart event related to the thing that was dropped.
                //
                //  For 'true' drop events (those not originating from a BodyDelegator
                //  event listener like DragStop), the dragInfo will be null.  In those
                //  cases, there will be a dragInfoId in the dataTransfer object which
                //  will allow the dragInfo associated with the dragged element to be looked up
                //
                // scope
                //  can be null or an angular scope.  Must be defined if expr is defined.
                //  will be used to
                //  1. evaluate the expression using scope.$eval(expr)
                //  2. wrap callbacks in scope.$apply if this.apply is true (true is the default)
                //
                // expr
                //  can be null or a string.  If defined then scope must also be defined.  If
                //  defined then callback must not be defined.  Will
                //  be used as the callback using scope.$eval(expr) with the $event local
                //
                // callback
                //  a function.  If defined then expr must not be defined
                initialize(...args) {
                    this._parseArguments(args);
                    const scope = this.scope;
                    const expr = this.expr;
                    let callback = this.callback;
                    const element = this.element;

                    const listener = this;

                    if (expr) {
                        callback = evt => {
                            scope.$eval(expr, {
                                $event: evt,
                            });
                        };
                    }

                    // wrap the callback so we can
                    // 1. add the dragInfo to the event
                    // 2. add movedX and movedY to the dragInfo event
                    // 3. ... more stuff in the future?
                    const wrappedCallback = function (evt) {
                        const exec = () => {
                            evt.dragAndDrop = listener.getDragInfo(evt);
                            listener.addMovedValues(evt);
                            callback.apply(this, [evt]);
                        };
                        if (listener.scope && listener.apply) {
                            safeApply(listener.scope, () => {
                                exec();
                            });
                        } else {
                            exec();
                        }
                    };
                    element[0].addEventListener(this.eventType, wrappedCallback, false);

                    this._destroy = function () {
                        element[0].removeEventListener(this.eventType, wrappedCallback);
                    };
                },

                // by default, we will wrap callbacks in a scope.$apply, but sometimes
                // we don't want to
                apply: true,

                destroy() {
                    this._destroy();
                },

                // Anytime an element with the 'draggable' directive is dragged,
                // the event should have dragInfo available on it.  Other things that
                // are dragged, however, like images and files, will not
                getDragInfo(evt) {
                    if (this.dragInfo) {
                        return this.dragInfo;
                    }

                    // the dragid is saved as one of the data types on the dataTransfer
                    // object (not as a value), so that it will be accessible in protected
                    // mode.  See the comment near setData in draggable_dir_helper for more info.
                    let dragId;
                    angular.forEach(evt.dataTransfer.types, type => {
                        if (type.slice(0, 6) === 'dragid') {
                            dragId = type.split(':')[1];
                        }
                    });
                    if (!dragId) {
                        return undefined;
                    }

                    const dragInfo = DragInfo.get(dragId);
                    if (!dragInfo) {
                        throw new Error(`No DragInfo instance found for ${dragId} in ${evt.type}event.`);
                    }
                    return dragInfo;
                },

                // I'm not sure if this is a bug, but not all events have reliable clientX and
                // clientY values.  Those that don't override this event and do nothing.
                addMovedValues(evt) {
                    const startEvent = evt.dragAndDrop.startEvent;
                    if (!startEvent) {
                        throw new Error(`No start event found in dragInfo for "${evt.type}" event`);
                    }
                    angular.extend(evt.dragAndDrop, {
                        movedX: evt.clientX - startEvent.clientX,
                        movedY: evt.clientY - startEvent.clientY,
                    });
                },

                _parseArguments(args) {
                    angular.forEach(args, arg => {
                        if (arg === null || arg === undefined) {
                            return;
                        }

                        if (typeof arg === 'string') {
                            this.expr = arg;
                        } else if (typeof arg === 'function') {
                            this.callback = arg;
                        } else if (arg.constructor && arg.constructor === DragInfo) {
                            this.dragInfo = arg;
                        } else if (arg.$apply) {
                            this.scope = arg;
                        } else if (arg.nodeName || arg.find) {
                            this.element = $(arg);
                        } else {
                            console.error('Unrecognized argument: ', arg);
                            throw new Error(`Unrecognized argument ${arg}`);
                        }
                    });
                    if (!this.element) {
                        throw new Error('element must be defined');
                    }
                    if (this.callback && this.expr) {
                        console.error('callback and expression cannot both be defined: ', {
                            callback: this.callback.toString(),
                            expr: this.expr,
                        });
                        throw new Error('callback and expression cannot both be defined.');
                    }
                    if (!this.callback && !this.expr) {
                        throw new Error('either callback or expression must be defined.');
                    }
                    if (this.expr && !this.scope) {
                        throw new Error('scope must be defined if expr is defined.');
                    }
                },
            };
        });

        /** ********************* Drop Events *********************** */
        /*
            DropEvents are called on an element that is a drop target (not on the element
            that is dragged).  Usually they will not have this.dragInfo set (the exception is in the BodyDelegator case below),
            so they will use the dragId in the evt.dataTransfer object to look up the dragInfo that
            was added by the dragstart callback when the drag was initiated.
        */
        EventListener.DropEvent = EventListener.subclass({
            addMovedValues(evt) {
                const startEvent = evt.dragAndDrop && evt.dragAndDrop.startEvent;
                if (startEvent) {
                    // see comment above on getDragInfo for an explanation of why startEvent
                    // might not be present.
                    angular.extend(evt.dragAndDrop, {
                        movedX: evt.clientX - startEvent.clientX,
                        movedY: evt.clientY - startEvent.clientY,
                    });
                }
            },
        });

        EventListener.Drop = EventListener.DropEvent.subclass({
            eventType: 'drop',
            callbackType: 'drop',
        });

        EventListener.DragOver = EventListener.DropEvent.subclass({
            eventType: 'dragover',
            callbackType: 'dragover',
            // there will be too many of these events to call scope.$apply on all of them,
            // things get too slow
            apply: false,
        });

        EventListener.DragEnter = EventListener.DropEvent.subclass({
            eventType: 'dragenter',
            callbackType: 'dragenter',
        });

        EventListener.DragLeave = EventListener.DropEvent.subclass({
            eventType: 'dragleave',
            callbackType: 'dragleave',
        });

        /** ********************* Drag Events *********************** */
        /*
            DragEvents are called on the element that is dragged.  They should always have
            this.dragInfo set, since, when they are initiated in DraggableHelper, they
            have access to the dragInfo object.  So they don't have to look up the dragInfo
            in the dataTransfer object.
        */
        EventListener.DragEvent = EventListener.subclass({
            // It seems like we should be able to define this for
            // dragend and drag, but those events do not seem to have
            // reliable clientX and clientY values.  Seems like browser bug,
            // but I'm not sure.  If they did have reliable values, the we wouldn't
            // need the dragstop and dragmove events below
            addMovedValues() {},
        });

        EventListener.DragStart = EventListener.subclass({
            eventType: 'dragstart',
            callbackType: 'dragstart',
            addMovedValues(evt) {
                angular.extend(evt.dragAndDrop, {
                    movedX: 0,
                    movedY: 0,
                });
            },
        });

        EventListener.DragEnd = EventListener.subclass({
            eventType: 'dragend',
            callbackType: 'dragend',
        });

        EventListener.Drag = EventListener.subclass({
            eventType: 'drag',
            callbackType: 'drag',
            // there will be too many of these events to call scope.$apply on all of them,
            // things get too slow
            apply: false,
        });

        /** ********************* Body Delegator Events *********************** */
        /*
            Seems like a bug, but the drag events (drag, dragend, etc.) do not
            have reliable clientX and clientY values.  For that reason, we are creating
            some pseudo-events, dragstop and dragmove, that replace dragend and drag,
            but do have reliable clientX and clientY.

            We do that by observing drop and drag events on the body (drop in the
            dragend/dragstop case and dragover in the drag/dragmove case), since the drop and
            dragover events do have reliable clientX and clientY values.

            Like DragEvents, these events have dragInfo set, since they are initialized
            in the DraggableHelper and have access to the dragInfo object at that time.
        */
        EventListener.BodyDelegator = EventListener.subclass(function () {
            this.extend({
                $observe: observe,
            });

            return {
                eventType: 'dragstart',

                initialize($super) {
                    // eslint-disable-next-line prefer-rest-params
                    this._parseArguments(Array.prototype.slice.call(arguments, 1));
                    const listener = this;

                    // we don't want to apply the passed in expression or callback
                    // to the dragStart event.  We want to save them for later
                    // to apply to the event that has been delegated to the body.
                    this.delegateExpr = this.expr;
                    this.delegateCallback = this.callback;
                    this.expr = null;
                    this.callback = null;
                    // any time a dragStart begins, start listening for a delegate event on the body.
                    // when one occurs, we will fire the callback
                    const dragStartCallback = () => {
                        listener._observeDelegateEventOnBody();
                    };
                    $super(this.element, this.scope, this.dragInfo, dragStartCallback);

                    // when a drag ends, we want to destroy delegate listeners.  new ones
                    // will be created on the next dragStart
                    // eslint-disable-next-line no-new
                    new EventListener.DragEnd(
                        this.element,
                        this.scope,
                        this.dragInfo,
                        this._destroyDelegateListeners.bind(this),
                    );
                },

                destroy($super) {
                    $super();
                    this._destroyDelegateListeners();
                },

                _observeDelegateEventOnBody() {
                    this._destroyDelegateListeners();

                    // create a listener to listen for delegate events on the body,
                    // using the callbacks that were passed into this listener's
                    // initialize (can't use apply with new apparently, so we need to handle
                    // the two cases separately)
                    const body = $($document[0].body);

                    // we need to call preventDefault on the dragover, otherwise
                    // the drop will not work.
                    this._dragOverListener = new EventListener.DragOver(this.dragInfo, body, e => {
                        if (e.preventDefault) {
                            e.preventDefault();
                        }
                        return false;
                    });

                    // eslint-disable-next-line new-cap
                    this._delegateListener = new this.delegateEventListener(
                        body,
                        this.dragInfo,
                        this.scope,
                        this.delegateExpr,
                        this.delegateCallback,
                    );
                },

                _destroyDelegateListeners() {
                    if (this._delegateListener) {
                        this._delegateListener.destroy();
                    }

                    if (this._dragOverListener) {
                        this._dragOverListener.destroy();
                    }
                },
            };
        });

        EventListener.DragStop = EventListener.BodyDelegator.subclass({
            callbackType: 'dragstop',
            delegateEventListener: EventListener.Drop,
        });

        EventListener.DragMove = EventListener.BodyDelegator.subclass({
            callbackType: 'dragmove',
            delegateEventListener: EventListener.DragOver,
        });

        return EventListener;
    },
]);
