
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility = window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility || {};
window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility.cert_acceptance_modal = window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility.cert_acceptance_modal || {};
window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility.cert_acceptance_modal = {...window.Smartly.locales.modules.en.back_royal.exec_ed_eligibility.cert_acceptance_modal, ...{
    "congratulations": "Congratulations",
    "you_are_eligible_to_enroll": "You are eligible to enroll in these Executive Education certificates.",
    "heading_ai_and_future_of_business": "AI & Future of Business (2 months)",
    "heading_data_and_finance": "Data & Finance (3-4 months)",
    "heading_rise_to_c_suite": "Rise to the C-Suite (8 months)",
    "review_and_register": "Review and Register",
    "learn_more": "Learn More",
    "recommended": "Recommended",
    "also_available": "Also Available"
}
}
    