import { ExecEdCertCategoryConfigs } from 'ExecEdEligibility/helpers/ExecEdCategories';
import { type ExecEdCertCategory } from 'ExecEdEligibility/ExecEdEligibility.types';
import { getProgramConfigValue, type ProgramType } from 'Program';
import { useTranslation } from 'react-i18next';
import { CertActionButton } from './CertActionButton';

type Props = {
    recommendedProgramType: ProgramType;
    category: ExecEdCertCategory;
    onReviewRegisterClick: (programType: ProgramType) => void;
    onLearnMoreClick: (programType: ProgramType) => void;
};

export function CertRecommendedProgram({
    recommendedProgramType,
    category,
    onReviewRegisterClick,
    onLearnMoreClick,
}: Props) {
    const { t } = useTranslation('back_royal');
    const categoryConfig = ExecEdCertCategoryConfigs[category];

    const programTitle = getProgramConfigValue(recommendedProgramType, 'standaloneShortProgramTitle');

    return (
        <div
            data-testid="recommended-program"
            className="tw-flex tw-w-[230px] tw-flex-col tw-items-center tw-justify-start tw-gap-[10px] tw-p-[5px]"
        >
            <div className="tw-mb-[10px] tw-text-xs tw-leading-4 tw-opacity-60">
                {t(categoryConfig.modalHeadingLocale.key, categoryConfig.modalHeadingLocale.values ?? {})}
            </div>
            <div className="tw-flex tw-justify-center tw-text-left tw-text-md tw-leading-[21px] xs:tw-justify-start">
                {programTitle as string}
            </div>
            <CertActionButton onClick={() => onReviewRegisterClick(recommendedProgramType)}>
                {t('exec_ed_eligibility.cert_acceptance_modal.review_and_register')}
            </CertActionButton>
            <button
                type="button"
                className="tw-border-none tw-bg-transparent tw-p-0 tw-text-xxs tw-leading-[14px] tw-underline"
                onClick={() => onLearnMoreClick(recommendedProgramType)}
            >
                {t('exec_ed_eligibility.cert_acceptance_modal.learn_more')}
            </button>
        </div>
    );
}
