// move the 'other' option to the last one in the dropdown list
function moveOtherToBottom(options) {
    const index = options.findIndex(option => option.value === 'other');
    if (index > -1) {
        const otherOption = options.splice(index, 1);
        options.push(...otherOption);
    }
}

export default {
    moveOtherToBottom,
};
