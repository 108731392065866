import { type AnyObject } from '@Types';

export class DisconnectedError extends Error {
    response?: AnyObject;

    constructor(response?: AnyObject) {
        super('Network is disconnected');
        this.response = response;
        this.name = 'DisconnectedError';
    }
}
