import {
    type Cohort,
    getPlaylistPackIds,
    getFoundationsPlaylistPackId,
    getStreamIsInCurriculum,
    getIsStreamInFoundationsPlaylist,
} from 'Cohorts';
import { type ProgramInclusion, streamIsManuallyUnlocked } from 'ProgramInclusion';

// This function purposely does not check id verification and tila verification. To understand
// why, note the comment at the top of content_access_helper about cases where access to an item
// is "specifically removed".
export function cohortUnlocksPlaylist(
    cohort: Cohort,
    programInclusion: ProgramInclusion | null,
    playlistLocalePackId: string,
) {
    const playlistPackIdsForCohort = getPlaylistPackIds(cohort);

    // If this playlist is not part of this cohort, then this cohort does not unlock it
    if (!playlistPackIdsForCohort.includes(playlistLocalePackId)) return false;

    // If the program inclusion has contentUnlocked that all related playlists are unlocked
    if (programInclusion?.contentUnlocked) return true;

    // The foundations playlist is always unlocked, even if content is not yet unlocked
    // for the program inclusion
    if (playlistLocalePackId === getFoundationsPlaylistPackId(cohort)) return true;

    return false;
}

// This function purposely does not check id verification and tila verification. To understand
// why, note the comment at the top of content_access_helper about cases where access to an item
// is "specifically removed".
export function cohortUnlocksStream(
    cohort: Cohort,
    programInclusion: ProgramInclusion | null,
    streamLocalePackId: string,
) {
    // If this stream is not part of this cohort, then this cohort does not unlock it
    if (!getStreamIsInCurriculum(cohort, streamLocalePackId)) return false;

    // If the program inclusion has contentUnlocked that all related streams are unlocked
    if (programInclusion?.contentUnlocked) return true;

    // always unlock all streams in the foundations playlist
    if (getIsStreamInFoundationsPlaylist(cohort, streamLocalePackId)) return true;

    // An admin may have manually marked the exam stream as unlocked on user's activeProgramInclusion.
    if (streamIsManuallyUnlocked(programInclusion, streamLocalePackId)) return true;

    return false;
}
