import angularModule from 'DialogModal/angularModule/scripts/dialog_modal';
import cacheAngularTemplate from 'cacheAngularTemplate';
import template from 'DialogModal/angularModule/views/dialog_modal_alert.html';

// NOTE: we're seeing infrequent errors with this template not being populated in $templateCache
// so we are logging with a provided name for Sentry fingerprinting and consistent metrics.
const templateUrl = cacheAngularTemplate(angularModule, 'dialog_modal_alert.html', template);

angularModule.directive('dialogModalAlert', [
    '$injector',

    $injector => {
        const $timeout = $injector.get('$timeout');
        const $document = $injector.get('$document');
        const safeApply = $injector.get('safeApply');
        const $rootScope = $injector.get('$rootScope');

        return {
            transclude: true,
            restrict: 'E',
            templateUrl,
            link(scope, elem, attrs, nullController, transclude) {
                // set styles for modal dialog
                const modalEl = elem.find('> div');
                const contentsEl = elem.find('[data-id="contents"]');

                scope.onClickModal = () => {
                    if (scope.closeOnClick) {
                        modalEl.modal('hide');
                    }
                };

                scope.onClickBackdrop = $event => {
                    if (scope.closeOnOutsideClick && $event.target.classList.contains('modal')) {
                        modalEl.modal('hide');
                    }
                };

                scope.modalIsVisible = () => $('.modal:visible').length > 0;

                // setup various display classes
                if (angular.isUndefined(scope.animated)) {
                    scope.animated = true;
                }
                scope.size = scope.size || 'large';

                scope.modalClasses = angular.isArray(scope.classes) ? angular.copy(scope.classes) : [];
                scope.modalClasses.push('modal');
                scope.modalClasses.push(scope.size);

                if (scope.animated) {
                    scope.modalClasses.push('fade');
                }

                if (!scope.title) {
                    scope.modalClasses.push('no-header');
                }

                // transclude the inner content
                // NOTE: if compiling this element fails, the error will be swallowed
                // up (at least in specs)
                transclude(scope, clone => {
                    contentsEl.append(clone);
                });

                // defer until digest is complete for creation, etc
                $timeout(() => {
                    // destroy the scope when the modal is hidden
                    modalEl.on('hidden.bs.modal', () => {
                        // if there's another modal that's visible, ensure that the modal-open class is present on the document
                        // body element since it's needed to make the browser scrollbar function properly when a modal is open.
                        // See "Scrollbar fix" section for the approved answer here: https://stackoverflow.com/questions/19305821
                        if (scope.modalIsVisible()) {
                            $($document[0].body).addClass('modal-open');
                        }
                        scope.$destroy();
                        safeApply($rootScope);
                    });

                    // hide the modal and call the "close" callback when
                    // the scope is destroyed
                    scope.$on('$destroy', () => {
                        modalEl.off('hidden.bs.modal');
                        modalEl.modal('hide');
                        if (scope.close) {
                            scope.close();
                        }
                        elem.remove();
                    });

                    // show the modal after initial digest
                    modalEl.modal({
                        show: true,

                        // these two options make it so the modal will not be dismissed
                        // if the user clicks outside or presses esc
                        backdrop: 'static',
                        keyboard: false,
                    });
                });
            },
        };
    },
]);
