
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.content_item = window.Smartly.locales.modules.en.back_royal.content_item || {};
window.Smartly.locales.modules.en.back_royal.content_item.is_content_item_mixin = window.Smartly.locales.modules.en.back_royal.content_item.is_content_item_mixin || {};
window.Smartly.locales.modules.en.back_royal.content_item.is_content_item_mixin = {...window.Smartly.locales.modules.en.back_royal.content_item.is_content_item_mixin, ...{
    "locked": "LOCKED",
    "resume": "RESUME",
    "retake": "RETAKE",
    "start": "START",
    "closed": "CLOSED",
    "unknown": "Unknown",
    "review": "REVIEW"
}
}
    