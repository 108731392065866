import * as Sentry from '@sentry/browser';
import { type OfflineModeManager } from 'OfflineMode';
import { type Transaction as SentryTransaction } from '@sentry/types';
import { type Config as FrontRoyalConfig, AppEnvType } from 'FrontRoyalConfig';
import { type SentryTags, type FrontRoyalTracingTags, type FrontRoyalSamplingContext } from './ErrorLogging.types';

export const GOTO_FRAME_TRACING_NAME = 'gotoFrame';
export const SHOW_FINISH_SCREEN_TRACING_NAME = 'showFinishScreen';
export const FLUSH_STORE_TRACING_NAME = 'flushStore';

const samplingRates: Record<string, number> = {
    [GOTO_FRAME_TRACING_NAME]: 0.001,
    [FLUSH_STORE_TRACING_NAME]: 0.0001,
};

/*
    This is a wrapper around Sentry.startTransaction that adds some default tags to the transaction.

    Note that, by default, 100% of transactions are sampled. If you want to sample a smaller amount
    for the transaction you're adding, update tracesSampler below
*/
export function startSentryTransaction(
    injector: angular.auto.IInjectorService,
    name: string,
    tags: SentryTags = {},
): SentryTransaction {
    return Sentry.startTransaction({
        name,
        tags: {
            ...tracingTags(injector),
            ...tags,
        },
    });
}

export function tracesSampler(samplingContext: FrontRoyalSamplingContext, frontRoyalConfig: FrontRoyalConfig) {
    let samplingRate: number | boolean | null = null;
    const name: string | undefined = samplingContext.transactionContext?.name;

    const { cordova } = samplingContext.transactionContext?.tags || {};

    if (name && name in samplingRates) {
        samplingRate = samplingRates[name];
    }

    if (name === SHOW_FINISH_SCREEN_TRACING_NAME) {
        samplingRate = frontRoyalConfig.debugFrameNavPerf() && cordova ? true : 0.005;
    }

    if (frontRoyalConfig.appEnvType() === AppEnvType.development) {
        // Give a warning in development if we've forgotten to set the sampling rate for some
        // transaction ...
        if (samplingRate === null) {
            throw new Error(`No sampling rate set for ${name || 'unnamed transaction'}`);
        }

        // ... but the sampling rate in development is always 100%.
        return true;
    }

    // If we do let a transaction slip through to production without defining a sampling rate,
    // default to 0.01
    return samplingRate === null ? 0.01 : samplingRate;
}

function tracingTags(injector: angular.auto.IInjectorService): FrontRoyalTracingTags {
    return {
        cordova: !!window.CORDOVA,
        in_offline_mode: injector.get<OfflineModeManager>('offlineModeManager')?.inOfflineMode || false,
    };
}
