import angularModule from 'DragAndDrop/angularModule/scripts/lib/drag_and_drop';

angularModule.directive('dragsort', [
    'DraggableDirHelper',
    'DroppableDirHelper',
    'DragAndDrop.EventListener',
    (DraggableDirHelper, DroppableDirHelper, EventListener) => ({
        link(scope, elem, attr) {
            elem.get(0).classList.add('dragsort');
            elem.attr('draggable', 'true');
            elem.attr('droppable', 'true');
            const expr = attr.dragsort.split(' in ');
            const itemExpr = expr[0];
            const listExpr = expr[1];
            if (attr['drag-data']) {
                throw new Error('drag-data cannot be defined on dragsort directive');
            }
            attr.$set('dragData', itemExpr);

            function item() {
                const _item = scope.$eval(itemExpr);
                if (_item === undefined || _item === null) {
                    throw new Error(`No item returned by expression: "${itemExpr}"`);
                }
                return _item;
            }

            function list() {
                const _list = scope.$eval(listExpr);
                if (_list === undefined) {
                    throw new Error(`No list returned by expression: "${listExpr}"`);
                }
                if (Object.prototype.toString.call(_list) !== '[object Array]') {
                    throw new Error(`List returned by expression is not an array: "${listExpr}"`);
                }
                return _list;
            }

            function index() {
                const _index = list().indexOf(item());
                if (_index === -1) {
                    throw new Error('Item is not in list');
                }
                return _index;
            }

            new DraggableDirHelper(elem, scope, attr);
            new DroppableDirHelper(elem, scope, attr);

            const listeners = [];
            listeners.push(
                new EventListener.Drop(scope, elem, evt => {
                    const _list = list();
                    const draggedItem = evt.dragAndDrop.data;
                    const draggedIndex = _list.indexOf(draggedItem);
                    const draggedItemInList = draggedIndex !== -1;

                    const droppedOnIndex = index();

                    // remove the dragged item if it's in the list and determine where to place it
                    let targetIndex;
                    if (draggedItemInList) {
                        _list.splice(draggedIndex, 1);
                        targetIndex = droppedOnIndex < draggedIndex ? droppedOnIndex : droppedOnIndex - 1;
                    } else {
                        targetIndex = droppedOnIndex;
                    }

                    // add the dragged item in the target spot
                    _list.splice(targetIndex, 0, draggedItem);
                }),
            );

            scope.$on('$destroy', () => {
                EventListener.$stopObserving(attr);
                angular.forEach(listeners, listener => {
                    listener.destroy();
                });
            });
        },
    }),
]);
