import angularModule from 'DragAndDrop/angularModule/scripts/lib/drag_and_drop';
/*
    included in directives that add draggable functionality (current draggable and dragsort)
*/
angularModule.factory('DraggableDirHelper', [
    'AClassAbove',
    'DragAndDrop.EventListener',
    'DragAndDrop.DragInfo',
    (AClassAbove, EventListener, DragInfo) => {
        const DraggableDirHelper = AClassAbove.subclass(() => ({
            initialize(element, scope, attrs) {
                scope.dragInfo = DragInfo.new();

                if (attrs) {
                    attrs.$observe('draggable', value => {
                        if (!value) {
                            attrs.$set('draggable', 'true');
                            element[0].draggable = true;
                        } else {
                            element[0].draggable = scope.$eval(value);
                        }
                    });
                }

                const listeners = [];

                // add the dragData and the startEvent to the dragInfo
                // FIXME: make dragInfo set up this listener itself when it is
                // initiated (it will have to take an element in it's initialize)
                // That is more consistent, because dragInfo does not work unless this
                // step happens, so dragInfo should enforce that this step is always done.
                listeners.push(
                    new EventListener.DragStart(scope.dragInfo, element, e => {
                        const expr = attrs.dragData;
                        if (expr) {
                            scope.dragInfo.data = scope.$eval(expr);
                        }
                        scope.dragInfo.startEvent = e;

                        // by setting the dragId as the TYPE, rather than the VALUE, we make
                        // it accessible to all events, even in protected mode,
                        // which is the case for dragenter and dragover.
                        // If we set it as the value, then we can't access it in protected mode.
                        // This is not a security concern because even if an element with a dragId
                        // is dropped on another window, there is no information in the dragId
                        // that can be stolen.
                        e.dataTransfer.setData(`dragid:${scope.dragInfo.dragId}`, '');
                        return false;
                    }),
                );

                // setup callbacks that add and remove classes
                listeners.push(
                    new EventListener.DragStart(scope.dragInfo, element, function (e) {
                        e.dataTransfer.effectAllowed = 'move';
                        this.classList.add('drag');
                        return false;
                    }),
                );

                listeners.push(
                    new EventListener.DragEnd(scope.dragInfo, element, function () {
                        this.classList.remove('drag');
                        return false;
                    }),
                );

                // watch the attributes for changes in user-defined callbacks
                EventListener.DragStart.$observe(scope.dragInfo, element, scope, attrs);
                EventListener.DragStop.$observe(scope.dragInfo, element, scope, attrs);
                EventListener.DragMove.$observe(scope.dragInfo, element, scope, attrs);
                EventListener.DragEnd.$observe(scope.dragInfo, element, scope, attrs);
                EventListener.Drag.$observe(scope.dragInfo, element, scope, attrs);

                // FIXME: dry up with droppablehelper
                scope.$on('$destroy', () => {
                    EventListener.$stopObserving(attrs);
                    angular.forEach(listeners, listener => {
                        listener.destroy();
                    });
                    scope.dragInfo.destroy();
                });
            },
        }));

        return DraggableDirHelper;
    },
]);
