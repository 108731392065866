import { useEffect } from 'react';
import { type ExecEdEligibilityBundle, type ExecEdCertCategory } from 'ExecEdEligibility/ExecEdEligibility.types';
import { useTranslation } from 'react-i18next';
import { ExecEdCertCategoryConfigs } from 'ExecEdEligibility/helpers/ExecEdCategories';
import { programCategoryForProgramType } from 'ExecEdEligibility/helpers/utils';
import { useWindowSize } from 'react-use';
import { getProgramConfigValue, type ProgramType } from 'Program';
import { CertActionButton } from './CertActionButton';

type Props = {
    category: ExecEdCertCategory;
    eligibilityBundle: ExecEdEligibilityBundle;
    onReviewRegisterClick: (programType: ProgramType) => void;
    onLearnMoreClick: (programType: ProgramType) => void;
};

export function CertEligibleProgramCategory({
    category,
    eligibilityBundle,
    onReviewRegisterClick,
    onLearnMoreClick,
}: Props) {
    const { t } = useTranslation('back_royal');
    const categoryConfig = ExecEdCertCategoryConfigs[category];
    const eligibleProgramsToDisplay = eligibilityBundle.eligiblePrograms.filter(
        p =>
            programCategoryForProgramType(p.programType) === category &&
            p.programType !== eligibilityBundle.recommendedProgramType,
    );

    const { width } = useWindowSize();
    const isSmOrWider = width >= 576;

    // Ensure all program title elements with a category are the same height so that the action buttons
    // align within the same row when at a viewport width where we display 2 programs in a row.
    useEffect(() => {
        const getProgramTitleElement = (programType: string) => document.getElementById(`program-title-${programType}`);
        const greatestProgramTitleHeight = eligibleProgramsToDisplay.reduce((acc, program) => {
            const programTitleElement = getProgramTitleElement(program.programType);
            return programTitleElement ? Math.max(acc, programTitleElement.clientHeight) : acc;
        }, 0);
        eligibleProgramsToDisplay.forEach(program => {
            const programTitleElement = getProgramTitleElement(program.programType);
            if (!programTitleElement) return;
            programTitleElement.style.height = isSmOrWider ? `${greatestProgramTitleHeight}px` : 'auto';
        });
    }, [eligibleProgramsToDisplay, isSmOrWider]);

    return (
        <div data-testid="program-category" className="tw-mb-[30px]">
            <div className="tw-mb-[10px] tw-flex tw-items-center tw-justify-center tw-text-xs tw-leading-4 tw-opacity-60">
                {t(categoryConfig.modalHeadingLocale.key, categoryConfig.modalHeadingLocale.values ?? {})}
            </div>
            <div className="tw-mx-auto tw-flex tw-justify-center xs:tw-ms-[130px]">
                <div className="tw-flex tw-w-full tw-flex-wrap tw-justify-center tw-gap-x-[20px] tw-gap-y-[30px] xs:tw-w-[550px] xs:tw-min-w-[550px] xs:tw-justify-start">
                    {eligibleProgramsToDisplay.map(program => {
                        const programTitle = getProgramConfigValue(program.programType, 'standaloneShortProgramTitle');

                        return (
                            <div
                                key={program.programType}
                                data-testid="program"
                                className="tw-flex tw-w-[80%] tw-flex-col tw-items-center tw-gap-[10px] xs:tw-w-[230px] xs:tw-items-start"
                            >
                                <div
                                    id={`program-title-${program.programType}`}
                                    className="tw-flex tw-justify-center tw-text-center tw-text-md tw-leading-[21px] xs:tw-justify-start xs:tw-text-start"
                                >
                                    {programTitle as string}
                                </div>
                                <CertActionButton
                                    className="tw-border-opacity-20"
                                    onClick={() => onReviewRegisterClick(program.programType)}
                                >
                                    {t('exec_ed_eligibility.cert_acceptance_modal.review_and_register')}
                                </CertActionButton>
                                <button
                                    type="button"
                                    className="tw-border-none tw-bg-transparent tw-p-0 tw-text-xxs tw-leading-[14px] tw-underline"
                                    onClick={() => onLearnMoreClick(program.programType)}
                                >
                                    {t('exec_ed_eligibility.cert_acceptance_modal.learn_more')}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
