import angularModule from 'ContentItem/angularModule/scripts/content_item_module';

angularModule.factory('LocalePack', [
    '$injector',

    $injector => {
        const Iguana = $injector.get('Iguana');

        const LocalePack = Iguana.subclass(function () {
            this.alias('LocalePack');

            this.setCallback('after', 'copyAttrsOnInitialize', function () {
                // lesson locale packs do not actually have groups.  Just streams
                // and playlist.  But this should not cause any problems, hopefully.
                this.groups = this.groups || [];
                this.content_items = this.content_items || [];

                // similarly, just streams have content topics
                this.content_topics = this.content_topics || [];
            });

            Object.defineProperty(this.prototype, 'groupNames', {
                get() {
                    return _.chain(this.groups).map('name').value().sort();
                },
            });

            Object.defineProperty(this.prototype, 'availableNonEnglishLocaleString', {
                get() {
                    // theoretically, it is not safe to cache this, but in practice
                    // the way we use it it should be ok
                    if (!this.$$availableNonEnglishLocaleString) {
                        const locales = _.chain(this.content_items).map('locale').without('en').sort();
                        this.$$availableNonEnglishLocaleString = locales.some().value()
                            ? ` (${locales.join(', ')})`
                            : '';
                    }
                    return this.$$availableNonEnglishLocaleString;
                },
            });

            return {
                getContentItem(preferredLocales) {
                    for (const locale of preferredLocales) {
                        const contentItem = this.contentItemForLocale(locale);
                        if (contentItem) {
                            return contentItem;
                        }
                    }
                },

                contentItemIdForLocale(locale) {
                    const contentItem = this.contentItemForLocale(locale);
                    return contentItem && contentItem.id;
                },

                setContentItemForLocale(locale, newContentItem) {
                    const newContentItemId = newContentItem && newContentItem.id;
                    if (this.contentItemIdForLocale(locale) === newContentItemId) {
                        return;
                    }

                    // remove the existing one
                    this.content_items = _.reject(
                        this.content_items,
                        contentItem => contentItem.locale === locale && contentItem.id !== newContentItemId,
                    );

                    if (newContentItem) {
                        this.content_items.push({
                            locale,
                            id: newContentItem.id,
                            title: newContentItem.title,
                        });
                    }
                },

                contentItemForLocale(locale) {
                    return _.find(this.content_items, {
                        locale,
                    });
                },

                clone() {
                    return this.constructor.new(this.asJson());
                },

                inGroup(groupName) {
                    return _.includes(this.groupNames, groupName);
                },
            };
        });

        return LocalePack;
    },
]);
