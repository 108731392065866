import angularModule from 'EmailInput/angularModule/scripts/email_input_module';

angularModule.factory('freeEmailDomains', [
    '$injector',

    $injector => {
        // see http://stackoverflow.com/questions/13818186/converting-shell-wildcards-to-regex
        function globStringToRegex(str) {
            const regex = new RegExp(preg_quote(str).replace(/\\\*/g, '.*').replace(/\\\?/g, '.'), 'g');
            regex.src = str; // for debugging
            return regex;
        }

        function preg_quote(str, delimiter) {
            // http://kevin.vanzonneveld.net
            // +   original by: booeyOH
            // +   improved by: Ates Goral (http://magnetiq.com)
            // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
            // +   bugfixed by: Onno Marsman
            // +   improved by: Brett Zamir (http://brett-zamir.me)
            // *     example 1: preg_quote("$40");
            // *     returns 1: '\$40'
            // *     example 2: preg_quote("*RRRING* Hello?");
            // *     returns 2: '\*RRRING\* Hello\?'
            // *     example 3: preg_quote("\\.+*?[^]$(){}=!<>|:");
            // *     returns 3: '\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:'
            return `${str}`.replace(new RegExp(`[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\${delimiter || ''}-]`, 'g'), '\\$&');
        }

        const freeEmailDomainList = $injector.get('FREE_EMAIL_DOMAIN_LIST');

        const freeEmailDomains = {
            isFreeEmail(email) {
                if (!email) {
                    return false;
                }
                const domain = email.split('@')[1];
                return this._isFreeDomain(domain);
            },

            _isFreeDomain(domain) {
                if (!domain) {
                    return false;
                }

                if (this.domainSet[domain]) {
                    return true;
                }

                return !!_.find(this.domainMatchers, matcher => domain.match(matcher));
            },
        };

        Object.defineProperty(freeEmailDomains, 'domainSet', {
            get() {
                const self = this;
                if (!self._domainSet) {
                    self._domainSet = {};
                    _.forEach(freeEmailDomainList, domain => {
                        if (!domain.includes('*')) {
                            self._domainSet[domain] = true;
                        }
                    });
                }

                return self._domainSet;
            },
        });

        Object.defineProperty(freeEmailDomains, 'domainMatchers', {
            get() {
                if (!this._domainMatchers) {
                    this._domainMatchers = _.chain(freeEmailDomainList)
                        .map(domain => (domain.includes('*') ? globStringToRegex(domain) : null))
                        .compact()
                        .value();
                }

                return this._domainMatchers;
            },
        });

        return freeEmailDomains;
    },
]);
