import { type CurrentUserIguanaObject } from 'Users';
import { type StreamIguanaObject } from 'Lessons';
import { VerificationMethod } from './ExamVerification.types';
import { getOrGenerateExamVerificationSessionId } from './getOrGenerateExamVerificationSessionId';

// Gets an ExamVerification that matches the current Stream/ExamVerificationSession. Can
// be either a BioSig verification, or a previously claimed admin verification.
export function examVerificationRecordForExamVerificationSession(
    user: CurrentUserIguanaObject,
    stream: StreamIguanaObject,
) {
    return user?.exam_verifications?.find(
        ev =>
            // Technically, this check on locale_pack_id isn't strictly needed here as we generate a unique
            // ExamVerificationSession for each exam Stream.
            ev.stream_locale_pack_id === stream.localePackId &&
            ev.exam_verification_session_id === getOrGenerateExamVerificationSessionId(stream),
    );
}

// Gets an unclaimed admin ExamVerification that matches the Stream's locale_pack_id.
export function unclaimedAdminExamVerificationRecordForStream(
    user: CurrentUserIguanaObject,
    stream: StreamIguanaObject,
) {
    return user?.exam_verifications?.find(
        ev =>
            ev.verification_method === VerificationMethod.Admin &&
            ev.stream_locale_pack_id === stream.localePackId &&
            ev.exam_verification_session_id === null,
    );
}

export function examVerificationRecordForStream(user: CurrentUserIguanaObject, stream: StreamIguanaObject) {
    return (
        // Order is important here because we claim unclaimed admin ExamVerification records to launch exams.
        // The presence of an ExamVerification corresponding to the Stream's ExamVerificationSessionId on this device
        // implies that the user was able to successfully verify their identity on this device, either through BioSig
        // or by previously claiming an unclaimed admin ExamVerification.
        // We should always give an ExamVerification corresponding to the ExamVerificationSessionId preference so we don't
        // accidentally consume an admin ExamVerification on a device where it's not required.
        examVerificationRecordForExamVerificationSession(user, stream) ||
        unclaimedAdminExamVerificationRecordForStream(user, stream)
    );
}
