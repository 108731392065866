import DeviseTokenAuthClient from 'vanilla-token-auth';
import 'HttpWrappers/angularModule';

export default angular
    .module('DeviseTokenAuthClient', ['HttpWrappers'])
    .factory('ngDeviseTokenAuthClient', [
        '$injector',
        $injector => {
            const $rootScope = $injector.get('$rootScope');
            const $window = $injector.get('$window');
            const $location = $injector.get('$location');

            let transport;
            let storage;
            if ($window.CORDOVA) {
                transport = 'headers';
                storage = 'localStorage';
            } else {
                transport = 'cookies';
                storage = undefined;
            }

            return new DeviseTokenAuthClient({
                // every API request originating from the ENDPOINT_ROOT
                apiUrl: `${window.ENDPOINT_ROOT}/api`,

                // defer validation for explicit validation once auth listeners are setup
                validateOnPageLoad: false,

                // Using headers as the transport and storing the token in localStorage is
                // required for Cordova since it does not support cookies
                transport,
                storage,

                // rely on auth events to perform redirect as necessary
                confirmationSuccessUrl: `${window.ENDPOINT_ROOT}/`,
                passwordResetSuccessUrl: `${window.ENDPOINT_ROOT}/settings/profile`,

                omniauthWindowType: 'sameWindow',

                httpWrapper: $injector.get('ngHttpWrapper'),

                // FIXME: once https://github.com/theblang/vanilla-token-auth/pull/10 is merged,
                // need to get the const AuthProviderPathsDefaultConfig from vanilla-token-auth as the default authProviderPaths,
                // then add the custom providers
                authProviderPaths: {
                    google_oauth2: '/auth/google_oauth2',
                    facebook: '/auth/facebook',
                    apple_quantic: '/auth/apple_quantic',
                    apple_smartly: '/auth/apple_smartly',
                    onelogin: '/auth/onelogin',
                    wechat_web: '/auth/wechat_web',
                    wechat_official_account: '/auth/wechat_official_account',
                },

                broadcast(event, payload) {
                    $rootScope.$broadcast(event, payload);
                },

                navigate(url, replace) {
                    if (replace) {
                        return $window.location.replace(url);
                    }
                    return $location.url(url);
                },
            });
        },
    ])
    .config([
        '$httpProvider',
        $httpProvider => {
            // responses are sometimes returned out of order. check that response is
            // current before saving the auth data.
            function tokenIsCurrent(ngDeviseTokenAuthClient, headers) {
                const oldTokenExpiry = Number(ngDeviseTokenAuthClient.getExpiry());
                const newTokenExpiry = Number(ngDeviseTokenAuthClient.getConfig().parseExpiry(headers || {}));

                return newTokenExpiry >= oldTokenExpiry;
            }

            // uniform handling of response headers for success or error conditions
            function updateHeadersFromResponse(ngDeviseTokenAuthClient, resp) {
                const newHeaders = {};
                const tokenFormat = ngDeviseTokenAuthClient.getConfig().tokenFormat;
                Object.keys(tokenFormat).forEach(key => {
                    if (resp.headers(key)) {
                        newHeaders[key] = resp.headers(key);
                    }
                });

                if (tokenIsCurrent(ngDeviseTokenAuthClient, newHeaders)) {
                    ngDeviseTokenAuthClient.setAuthHeaders(newHeaders);
                }
            }

            // We only use headers and localStorage in Cordova, where cookies are not supported. So we only need
            // this interceptor logic there.
            if (window.CORDOVA) {
                // this is ugly...
                // we need to configure an interceptor (must be done in the configuration
                // phase), but we need access to the $http service, which is only available
                // during the run phase. the following technique was taken from this
                // stackoverflow post:
                // http://stackoverflow.com/questions/14681654/i-need-two-instances-of-angularjs-http-service-or-what
                $httpProvider.interceptors.push([
                    '$injector',
                    $injector => ({
                        request(req) {
                            $injector.invoke([
                                '$http',
                                'ngDeviseTokenAuthClient',
                                // eslint-disable-next-line consistent-return
                                function onRequest(_$http, ngDeviseTokenAuthClient) {
                                    if (req.url.match(ngDeviseTokenAuthClient.apiUrl())) {
                                        return (() => {
                                            const result = [];
                                            const object = ngDeviseTokenAuthClient.retrieveData('auth_headers') || {};
                                            Object.keys(object).forEach(key => {
                                                result.push((req.headers[key] = object[key]));
                                            });
                                            return result;
                                        })();
                                    }
                                },
                            ]);

                            return req;
                        },

                        response(resp) {
                            $injector.invoke([
                                '$http',
                                'ngDeviseTokenAuthClient',
                                // eslint-disable-next-line consistent-return
                                function onResponse(_$http, ngDeviseTokenAuthClient) {
                                    if (resp.config.url.match(ngDeviseTokenAuthClient.apiUrl())) {
                                        return updateHeadersFromResponse(ngDeviseTokenAuthClient, resp);
                                    }
                                },
                            ]);

                            return resp;
                        },

                        responseError(resp) {
                            $injector.invoke([
                                '$http',
                                'ngDeviseTokenAuthClient',
                                // eslint-disable-next-line consistent-return
                                function onResponseError(_$http, ngDeviseTokenAuthClient) {
                                    if (
                                        resp &&
                                        resp.config &&
                                        resp.config.url &&
                                        resp.config.url.match(ngDeviseTokenAuthClient.apiUrl())
                                    ) {
                                        return updateHeadersFromResponse(ngDeviseTokenAuthClient, resp);
                                    }
                                },
                            ]);

                            return $injector.get('$q').reject(resp);
                        },
                    }),
                ]);

                // define http methods that may need to carry auth headers
                const httpMethods = ['get', 'post', 'put', 'patch', 'delete'];

                // disable IE ajax request caching for each of the necessary http methods
                httpMethods.forEach(method => {
                    if ($httpProvider.defaults.headers[method] == null) {
                        $httpProvider.defaults.headers[method] = {};
                    }
                    $httpProvider.defaults.headers[method]['If-Modified-Since'] = 'Mon, 26 Jul 1997 05:00:00 GMT';
                });
            }
        },
    ])
    .run(['ngDeviseTokenAuthClient', ngDeviseTokenAuthClient => ngDeviseTokenAuthClient.initialize()]);
