import angularModule from 'EmailInput/angularModule/scripts/email_input_module';

angularModule.directive('requireDomain', [
    '$injector',
    $injector => ({
        require: 'ngModel',

        link(scope, element, attrs, modelCtrl) {
            const TranslationHelper = $injector.get('TranslationHelper');

            modelCtrl.$validators.domain = (modelValue, viewValue) => {
                const value = modelValue || viewValue;
                const currentDomain = value && value.split('@')[1];

                const options = scope.$eval(attrs.requireDomain);
                const requireDomain = options.domain;
                const errors = options.errors;

                const _errors = [];
                if (!currentDomain || currentDomain.toLowerCase() !== requireDomain.toLowerCase()) {
                    _errors.push(
                        new TranslationHelper('email_input.email_validation').get('please_enter_matching_domain', {
                            requireDomain,
                        }),
                    );
                }

                if (errors && _.some(_errors)) {
                    errors[attrs.name] = _errors.join(', ');
                } else if (errors) {
                    delete errors[attrs.name];
                }

                return !_.some(_errors);
            };
        },
    }),
]);
