import { type SnakeCaseKeys } from '@Types';

export enum VerificationMethod {
    BioSig = 'biosig',
    Admin = 'admin',
}

export type ExamVerification = {
    id: string;
    userId: string;
    examVerificationSessionId?: string | null;
    streamLocalePackId: string;
    verificationMethod: VerificationMethod;
    expiresAt: number;
};

export type ExamVerificationSnakeCasedAttrs = SnakeCaseKeys<ExamVerification>;
